import React from 'react';
import useEvents from '../hooks/useEvents';
import EventCard from './EventCard';
import Loading from "../../common/Loading";
import SectionPaper from '../../common/SectionPaper';
import EmptyMessage from '../../common/EmptyMessage';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
} from "@mui/material";
import { NameTableCell, MessageTableCell, DateTableCell, StatusTableCell, ActionTableCell } from "./tableCells";

const EmailCampaign = ({ refresh, newEvent, inProgressEventId, setInProgressEventId }) => {
    const { events, isLoading, handleDeleteEvent, refreshFunction, sendFunction, handelCancel,fetchEvents } = useEvents('email', refresh, newEvent,inProgressEventId, setInProgressEventId);

    return (
        <SectionPaper padding>
            {isLoading ? (
                <Loading />
            ) : events.length === 0 ? (
                <EmptyMessage message="No Emails to show." />
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <NameTableCell>Name</NameTableCell>
                            <MessageTableCell>Message</MessageTableCell>
                            <DateTableCell>Date</DateTableCell>
                            <StatusTableCell>Status</StatusTableCell>
                            <ActionTableCell>Actions</ActionTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.map((event) => (
                            <EventCard
                                key={event._id}
                                event={event}
                                handleDelete={handleDeleteEvent}
                                inProgressEventId={inProgressEventId}
                                setInProgressEventId={setInProgressEventId}
                                sendFunction={sendFunction}
                                refreshFunction={refreshFunction}
                                handelCancel={handelCancel}
                                eventType="email"
                                fetchEvents = {fetchEvents}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
        </SectionPaper>
    );
};

export default EmailCampaign;
