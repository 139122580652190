import React, { useState, useEffect, useRef } from 'react'
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Pagination from "@material-ui/lab/Pagination"
import Chart from 'react-apexcharts'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Loading from "../../common/Loading"
import EmptyMessage from "../../common/EmptyMessage"
import * as query from "../api/action/query.js"

const Forecast = ({type, forecast}) => {
    const [isLoading, setLoading] = useState(false);
    const [selectedRange, setSelectedRange] = useState(13);
    const [forecastData, setForecastData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [monthlyData, setMonthlyData] = useState([]);
    const chartRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (forecastData.length > 0)    dataByMonth()
    }, [forecastData]);

    const fetchData = async ()=>{
        setLoading(true);
        try{
            const forecastResponse = await query.fetchForecastData(type);
            setForecastData(forecastResponse.data[0].output);
        }
        catch(error){
            console.error('Error fetching data:', error)
        }finally{
            setLoading(false)
        }
    };

    const dataByMonth = () => {
        const monthlyWiseData = forecastData.reduce((acc, data) => {
            const date = new Date(data.ds)
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
            if (!acc[monthYear]) acc[monthYear] = []
            acc[monthYear].push(data)
            return acc
        }, {})

        setMonthlyData(Object.entries(monthlyWiseData).sort())
    };

    const handleRangeChange = (event) =>  setSelectedRange(event.target.value)

    const handleRunClick = () => {
        // to handle run clicked event
    };

    const rangeDropdown = () => {
        const options = [];
        for (let i = 13; i <= 26; i++)  options.push(<MenuItem key={i} value={i}>{`${i} weeks`}</MenuItem>);
        return options;
    };

    const graphData = () => {
        const dates = forecastData.map(d => new Date(d.ds).getTime());
        const actuals = forecastData.map(d => parseFloat(d.Actuals));
        const forecasts = forecastData.map(d => parseFloat(d.Forecast));

        return {
            options: {
                chart: {
                    type: 'bar',
                    height: 600,
                    zoom: {
                        enabled: true,
                        type: 'x',
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'MMM dd yyyy'
                    },
                    tickAmount: 'dataPoints',
                },
                yaxis: {
                    title: {
                        text: (forecast==="Sales Forecast"?"Sales Amount":"Order")
                    },
                    labels: {
                        formatter: function(val) {return Math.round(val)}
                    },
                    min: 0,
                    tickAmount: 5,
                },
                title: {
                    text: forecast,
                    align: 'center'
                },
                legend: {
                    position: 'top'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                }
            },
            series: [
                {
                    name: 'Actuals',
                    data: dates.map((date, index) => ({ x: date, y: actuals[index] }))
                },
                {
                    name: 'Forecast',
                    data: dates.map((date, index) => ({ x: date, y: forecasts[index] }))
                }
            ]
        };
    };

    const tableRows = () => {
        return monthlyData[currentPage - 1][1].map((data, index) => (
            <TableRow key={index} style={{background:data.PredictionType ==="Actuals"? "#E9FCFC":"#EBFEEF"}}>
                <TableCell align="center">{new Date(data.ds).toLocaleDateString()}</TableCell>
                <TableCell align="center">{parseFloat(data.Actuals).toFixed(2)}</TableCell>
                <TableCell align="center">{parseFloat(data.Forecast).toFixed(2)}</TableCell>
            </TableRow>
        ));
    };

    const handleArrowClick = (direction) => {
        if (chartRef.current) {
            const chart = chartRef.current.chart
            const currentMin = chart.w.globals.minX
            const currentMax = chart.w.globals.maxX
            const range = currentMax - currentMin
            const shift = range * 0.3
            let newMin, newMax
            if (direction === 'left'){
                newMin = currentMin-shift
                newMax = currentMax-shift
            } else {
                newMin = currentMin+shift
                newMax = currentMax+shift
            }
            chart.zoomX(newMin, newMax)
        }
    };

    const handlePageChange = (event, value) => setCurrentPage(value)

    const chartData = graphData();

    return (
        <div >
            <div style={{ display:'flex', flexDirection:'row-reverse', gap:'10px'}}>
                <Button variant="contained" color="primary" onClick={handleRunClick} style={{marginRight:'30px'}}>Run</Button>
                <Select labelId="range-select-label" id="range-select" value={selectedRange} onChange={handleRangeChange} style={{ width: '100px'}}>
                    <MenuItem disabled>Select Limit</MenuItem>
                    {rangeDropdown()}
                </Select>
                <div style={{margin:'10px'}}>Period:</div>
            </div>
            {isLoading ? <Loading />: 
            forecastData.length === 0 ? (
                <EmptyMessage message="No Data" />
            ) : (
            <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center', marginTop:'10px'}}>
                <div style={{ position: 'relative', width: '1200px' }}>
                    <Button style={{ position: 'absolute', left:'-40px', top:'49%'}} onClick={() => handleArrowClick('left')}>
                        <ArrowBackIosIcon />
                    </Button>
                    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                        <Chart options={chartData.options} series={chartData.series} type="bar" width="100%" height="550" ref={chartRef}/>
                    </div>
                    <Button style={{ position: 'absolute', right:'-40px', top:'49%'}} onClick={() => handleArrowClick('right')}>
                        <ArrowForwardIosIcon />
                    </Button>
                </div>
                <Pagination count={monthlyData.length} page={currentPage} onChange={handlePageChange} style={{ margin: '20px' }}/>
                <TableContainer style={{ width: '50%', marginBottom: '20px', maxHeight:'350px'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Actuals</TableCell>
                                <TableCell align="center">Forecast</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            )}
        </div>
    )
}

export default Forecast