import React, { useState, useEffect } from "react";
import { Toolbar } from "@material-ui/core";
import {Typography, Dialog, DialogContent } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EmailCampaign from "./components/EmailCampaign";
import FaceBookPost from "./components/FaceBookPost";
import PushNotification from "./components/PushNotification";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {Announcement, Block} from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./styles.css";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import CreateEdit from "./CreateEdit";
import ViewEvent from "./components/ViewEvent";
import EmailReportTemplate from "./components/EmailReportTemplate";

const Marketing = () => {
    const [refresh, setRefresh] = useState(0);
    const [newEvent, setNewEvent] = useState(null);
    const [popUp, openPopUp] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [currTab, setCurrTab] = useState(() => {
        const path = location.pathname;
        if (path.includes('/fb-post')) return "FaceBookPost";
        if (path.includes('/push-notification')) return "PushNotification";
        return "Email";
    });
    const [inProgressEvents, setInProgressEvents] = useState(() => {
        if (!sessionStorage.getItem('sessionActive')) {
            localStorage.removeItem('inProgressemail');
            localStorage.removeItem('inProgressfb-post');
            localStorage.removeItem('inProgresspush-notification');
            sessionStorage.setItem('sessionActive', 'true');
            return {
                email: null,
                'fb-post': null,
                'push-notification': null
            };
        } else {
            return {
                email: localStorage.getItem('inProgressemail') || null,
                'fb-post': localStorage.getItem('inProgressfb-post') || null,
                'push-notification': localStorage.getItem('inProgresspush-notification') || null
            };
        }
    });

    useEffect(() => {
        const path = location.pathname;
        if (path.includes('/fb-post')) setCurrTab("FaceBookPost");
        else if (path.includes('/push-notification')) setCurrTab("PushNotification");
        else if (path.includes('/email')) setCurrTab("Email");
    }, [location.pathname]);

    const setInProgressEventId = (eventType, id) => {
        setInProgressEvents(prev => ({
            ...prev,
            [eventType]: id
        }));
        if (id) {
            localStorage.setItem(`inProgress${eventType}`, id);
        } else {
            localStorage.removeItem(`inProgress${eventType}`);
        }
    };

    const editRoute = () => {
        return location.pathname.includes('/edit/');
    };
    const isReportPage = () => {
        return location.pathname === "/messaging/bounced-email-ids" || location.pathname === "/messaging/spam-reports";
    };

    const refreshList = () => {
        setRefresh((prev) => prev + 1);
    };

    const addNewEvent = (event) => {
        setNewEvent(event);
    };

    const openCreatePopUp = () => {
        openPopUp(true);
    };

    const closePopup = () => {
        openPopUp(false);
    };
    const openBouncedEmails= ()=>{
        history.push("/messaging/bounced-email-ids");
    }

    const openSpamEmails= ()=>{
        history.push("/messaging/spam-reports");
    }

    const tabChange = (key) => {
        setCurrTab(key);
    };

    const updateExistingEvent = (updatedEvent) => {
        refreshList();
    };

    const isMessagingRoute = location.pathname === "/messaging";
    return (
        <>
            <Toolbar>
                <Typography variant="h6" color="inherit">
                    Messaging Tool
                </Typography>
            </Toolbar>
                <Container fluid>
                    <Row className="mb-3">
                        <Col style={{ padding: 0, minWidth: '00px', position: 'relative' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                            }}>
                                {!isReportPage() && (
                                <Tabs
                                    activeKey={currTab}
                                    onSelect={tabChange}
                                    transition={false}
                                    style={{ flex: 1, paddingRight: '48px' }}
                                    disabled={editRoute() || !isMessagingRoute}
                                >
                                    <Tab eventKey="Email" title={<span style={{ fontSize: "15px" }}>Email</span>} disabled={editRoute() || !isMessagingRoute}/>
                                    <Tab eventKey="PushNotification" title={<span style={{ fontSize: "15px" }}>Push Notification</span>} disabled={editRoute() || !isMessagingRoute}/>
                                    <Tab eventKey="FaceBookPost" title={<span style={{ fontSize: "15px" }}>Facebook Post</span>} disabled={editRoute() || !isMessagingRoute}/>
                                </Tabs>
                                )}
                                {!editRoute() && !isReportPage() && (
                                    <div style={{
                                        position: 'absolute',
                                        right: '80px',
                                        bottom: '0',
                                        paddingBottom: '8px',
                                    }}>
                                        <Tooltip title="Create Event">
                                            <IconButton
                                                color="primary"
                                                onClick={openCreatePopUp}
                                                aria-label="create event"
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Spam report">
                                            <IconButton
                                                color="error"
                                                onClick={openSpamEmails}
                                                aria-label="spam report"
                                            >
                                                <Block style={{ color: 'error' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Bounced emails">
                                            <IconButton
                                                color="warning"
                                                onClick={openBouncedEmails}
                                                aria-label="bounced email"
                                            >
                                                <Announcement style={{ color: 'orange' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Switch>
                                <Route exact path="/messaging">
                                    {currTab === "Email" && (
                                        <EmailCampaign
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents.email}
                                            setInProgressEventId={(id) => setInProgressEventId('email', id)}
                                        />
                                    )}
                                    {currTab === "FaceBookPost" && (
                                        <FaceBookPost
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents['fb-post']}
                                            setInProgressEventId={(id) => setInProgressEventId('fb-post', id)}
                                        />
                                    )}
                                    {currTab === "PushNotification" && (
                                        <PushNotification
                                            refresh={refresh}
                                            newEvent={newEvent}
                                            inProgressEventId={inProgressEvents['push-notification']}
                                            setInProgressEventId={(id) => setInProgressEventId('push-notification', id)}
                                        />
                                    )}
                                </Route>
                                <Route path="/messaging/email/edit/:id">
                                    <CreateEdit
                                        refreshList={refreshList}
                                        updateExistingEvent={updateExistingEvent}
                                        type="email"
                                        onClose={closePopup}
                                    />
                                </Route>
                                <Route path="/messaging/fb-post/edit/:id">
                                    <CreateEdit
                                        refreshList={refreshList}
                                        updateExistingEvent={updateExistingEvent}
                                        type="fb-post"
                                        onClose={closePopup}
                                    />
                                </Route>
                                <Route path="/messaging/push-notification/edit/:id">
                                    <CreateEdit
                                        refreshList={refreshList}
                                        updateExistingEvent={updateExistingEvent}
                                        type="push-notification"
                                        onClose={closePopup}
                                    />
                                </Route>
                                <Route path="/messaging/:type/view/:id">
                                    <ViewEvent />
                                </Route>
                                <Route path="/messaging/bounced-email-ids">
                                    <EmailReportTemplate type="bounced"/>
                                </Route>
                                <Route path="/messaging/spam-reports">
                                    <EmailReportTemplate type="spam"/>
                                </Route>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            <Dialog open={popUp} onClose={closePopup} fullWidth>
                <DialogContent>
                    <CreateEdit refreshList={refreshList} addNewEvent={addNewEvent} onClose={closePopup} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Marketing;