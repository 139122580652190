import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { getAllEarnedPoints } from "../api/actions/query"; 
import { messageShow } from '../../../common/api/actions';
import { withStyles } from '@material-ui/core/styles';

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'pie',
        },
        title: {
          text: 'Top Purchasers by Reward',
        },
        labels: [],
      },
      series: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getAllEarnedPoints, messageShow, isLoading } = this.props;
    this.isLoadingToggle(isLoading);
    try {
      const { data } = await getAllEarnedPoints();
      if (data.success) {
        const labels = data.data.map(item => item.user.name);
        const series = data.data.map(item => item.totalRewardsUsed);
      

        this.setState({
          options: {
            ...this.state.options,
            labels: labels,
          },
          series: series,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again.');
    } finally {
      this.isLoadingToggle(false);
    }
  }

  isLoadingToggle = (loading) => {
    // Handle the loading state here
    console.log('Loading state:', loading);
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="pie"
      />
    );
  }
}

PieChart.propTypes = {
  getAllEarnedPoints: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired, 
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  messageShow,
  getAllEarnedPoints,
};

export default connect(null, mapDispatchToProps)(withStyles()(PieChart));
