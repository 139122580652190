import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SentList = ({ eventData, type }) => {
    const history = useHistory();
    console.log(eventData, type)
    const listToDisplay = type === 'before' ? (eventData?.customEmails || []) : (eventData?.sentList || []);
    const BackButton = () => (
        <IconButton color="primary" onClick={() => history.goBack()} aria-label="Go back">
            <ArrowBackIcon />
        </IconButton>
    );

    if (listToDisplay.length === 0) {
        return (
            <Box sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
                    <BackButton />
                    <Typography variant="h6">{type === 'before' ? 'Custom Emails' : 'Sent List'}</Typography>
                </Box>
                <Box sx={{ paddingLeft: '40px' }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>Event: {eventData?.name || 'N/A'}</Typography>
                    <Typography>No data to display.</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <BackButton />
                <Typography variant="h6">{type === 'before' ? 'Custom Emails' : 'Sent List'}</Typography>
            </Box>
            <Typography variant="subtitle1" sx={{ marginBottom: 2, paddingLeft: '40px' }}>Event: {eventData?.name || 'N/A'}</Typography>
            <List sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
                {listToDisplay.map((item, index) => (
                    <ListItem key={index} divider>
                        <Box sx={{ minWidth: '30px', marginRight: '10px' }}>
                            <Typography variant="body2">{index + 1}.</Typography>
                        </Box>
                        {type === 'before' ? (
                            <ListItemText
                                primary={item}
                                primaryTypographyProps={{ variant: 'body1' }}
                            />
                        ) : (
                            <ListItemText
                                primary={item.email}
                                secondary={new Date(item.sentAt).toLocaleString()}
                                primaryTypographyProps={{ variant: 'body1' }}
                                secondaryTypographyProps={{ variant: 'body2' }}
                            />
                        )}
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default SentList;