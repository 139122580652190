import { styled } from "@mui/material/styles"
import { TableCell } from "@mui/material"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: theme.spacing(1),
}))

export const NameTableCell = styled(StyledTableCell)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: "150px",
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: "100px",
    },
}))

export const MessageTableCell = styled(StyledTableCell)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: "250px",
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: "150px",
    },
}))

export const DateTableCell = styled(StyledTableCell)({
    width: "auto",
    whiteSpace: "nowrap",
})

export const StatusTableCell = styled(StyledTableCell)({
    width: "fit-content",
    whiteSpace: "nowrap",
})

export const ActionTableCell = styled(StyledTableCell)({
    width: "fit-content",
    whiteSpace: "nowrap",
    textAlign: "center",
})

export const EmailTableCell = styled(StyledTableCell)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.down('md')]: {
        width: "auto",
    },
}))

export const ReasonTableCell = styled(StyledTableCell)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: "50%",
    },
    [theme.breakpoints.down('md')]: {
        width: "auto",
    },
    overflow: "visible",
    whiteSpace: "normal",
}))