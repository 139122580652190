// App Imports
import {API_URL,IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'

import Createrewards from'../Createrewards'
import UpdateRewards from "../UpdateRewards"

// Image
export function imagegoals(image = '/default.jpg') {
  return `${ API_URL }/${ params.goal.image.path }/${ image }`
}
export function getImageSource(image = '/default.jpg') {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
export default {
  
}
