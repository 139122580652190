import axios from "axios";
import { messageShow } from "../../../common/api/actions";
import { API_URL } from "../../../../setup/config/env";

export const getAllRatingFeedbacks = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/api/rating`);
    if (response.data.success) {
      return response.data;
    } else {
      dispatch(
        messageShow(response.data.message || "Failed to fetch feedbacks.")
      );
    }
  } catch (error) {
    console.error("Error fetching feedbacks:", error);
    dispatch(messageShow("An error occurred while fetching feedbacks."));
  }
};

export function detail({ userId }) {
  return (dispatch) => {
    return axios.post(API_URL, {
      operation: "userDetail",
      params: { userId },
    });
  };
}
