// Imports
import axios from "axios";

// App Imports
import { API_URL } from "../../../../setup/config/env";

// Actions
export function addFaq({ prompt, answer }) {
  return (dispatch) => {
    return axios.post(`${API_URL}/api/faq/add`, { prompt, answer });
  };
}

export function updateFaq(editStates) {
  return axios.post(`${API_URL}/api/faq/edit`, { editStates });
}

export function deleteFaq({ id }) {
  return (dispatch) => {
    return axios.delete(`${API_URL}/api/faq/delete/${id}`);
  };
}
