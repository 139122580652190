import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { messageShow } from "../../common/api/actions";
import { TableRow, IconButton, Tooltip, TableCell } from "@mui/material";
import { Delete, Send, FileCopy } from "@mui/icons-material";
import { NameTableCell, MessageTableCell, DateTableCell, StatusTableCell, ActionTableCell } from "./tableCells";
import { useHistory } from "react-router-dom";
import { createEvent } from "../api/action/mutation";
import routes from "../api/routes";

const EventCard = ({ event, handleDelete, inProgressEventId, setInProgressEventId, sendFunction, refreshFunction, handelCancel, eventType, fetchEvents, index}) => {
    const [status, setStatus] = useState(event.status);
    const [sentCount, setSentCount] = useState(event.sentCount || 0);
    const [totalCount, setTotalCount] = useState(event.totalCount || 0);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setStatus(event.status);
        setSentCount(event.sentCount || 0);
        setTotalCount(event.totalCount || 0);
    }, [event]);

    const handleEdit = () => {
        if (event.status === "Yet to Send") history.push(routes.editEvent.path(eventType, event._id));
        else dispatch(messageShow("Only events with 'Yet to Send' status can be edited."));
    };

    const handleRowClick = () => {
        if(event.status==="Yet to Send")
            history.push(routes.editEvent.path(eventType, event._id));
        else
            history.push(routes.viewEvent.path(eventType, event._id));
    };

    const handleActionClick = (e, action) => {
        e.stopPropagation();
        if (action) action();
    };

    const handleRefresh = async () => {
        try {
            const response = await refreshFunction(event._id);
            if (response) {
                setStatus(response.status);
                setSentCount(response.sentCount || 0);
                setTotalCount(response.totalCount || 0);
            }
        } catch (error) {
            dispatch(messageShow(error.message || "An error occurred"));
        }
    };

    const handleSend = async () => {
        if (inProgressEventId) {
            dispatch(messageShow(`Another ${eventType} event is already in progress`));
            return;
        }

        try {
            setStatus("In Progress");
            setInProgressEventId(event._id);
            const response = await sendFunction(event._id);
            if (response.message==="Completed") {
                setStatus("Completed");
                dispatch(messageShow(response.message));
                setInProgressEventId(null);
            } else{
                dispatch(messageShow(response.error));
                setInProgressEventId(null);
            }
        } catch (error) {
            setInProgressEventId(null);
            dispatch(messageShow(error.message || "An error occurred while sending"));
        }
    };

    const handleDeleteEvent = async () => {
        if (inProgressEventId === event._id) {
            if (window.confirm(`Do you want to delete the in process: ${event.name}?`)) {
                const response = await handelCancel(event._id);
                if (response?.message) {
                    setStatus("Cancelled");
                    if (eventType === "email")  localStorage.removeItem("inProgressemail")
                    else if(eventType==="fb-post")   localStorage.removeItem("inProgresfb-post");
                    else if(eventType==="push-notification")   localStorage.removeItem("inProgrespush-notification");
                    setInProgressEventId(null)
                }
                handleDelete(event._id);
            }
        } else {
            if (window.confirm(`Do you want to delete: ${event.name}?`)){
                handleDelete(event._id);
            }
        }
    };

    const handleClone = async () => {
        try {
            const clonedEventData = {
                name: `${event.name} (Clone)`,
                message: event.message,
                status: "Yet to Send",
                date: new Date().toISOString(),
                notificationType: eventType
            };
            if (eventType === 'email') {
                clonedEventData.subject = event.subject;
                clonedEventData.greeting = event.greeting;
            }
            if (eventType !== 'push-notification') {
                clonedEventData.eventImg = event.eventImg;
            }
            if (eventType !== 'fb-post') {
                clonedEventData.for = event.for;
                clonedEventData.customerJoinDays = event.for === 'age' ? event.customerJoinDays : 0;
            }
            if(event.for==='custom'){
                clonedEventData.emails = event.customEmails
            }
            const cloneEvent = await createEvent(clonedEventData);
            fetchEvents()
            console.log(cloneEvent);
        } catch (error) {
            console.log(error);
        }
    };

    const renderStatus = () => {
        const getStyle = (color) => ({
            padding: '4px 8px',
            borderRadius: '5px',
            fontSize: '12px',
            border: `1px solid ${color}`,
            backgroundColor: `${color}20`,
            color: color, width: '80px',
            height: '24px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center'
        });

        switch (status) {
            case "In Progress":
                return <span style={getStyle("#42a5f5")}>In Progress</span>;
            case "Cancelled":
                return <span style={getStyle("#ffa726")}>Cancelled</span>;
            case "Yet to Send":
                return <span style={getStyle("#78909c")}>Not Sent</span>;
            case "Completed":
                return <span style={getStyle("#66bb6a")}>Sent</span>;
            case "Failed":
                return <span style={getStyle("#ef5350")}>Failed</span>;
            default:
                return <span style={getStyle("#ef5350")}>Failed</span>;
        }
    };

    const setActionButtons = () => {
        const isYetToSend = status === "Yet to Send";
        const isInProgress = status === "In Progress";
        const isCompleted = status === "Completed" || status === "Failed" || status === "Cancelled";

        const buttons = {
            send: (
                <Tooltip title={isInProgress ? `This ${eventType} event is in progress` : "Send"}>
                    <span>
                        <IconButton
                            aria-label="send"
                            size="large"
                            onClick={(e) => handleActionClick(e, handleSend)}
                            disabled={!isYetToSend || inProgressEventId}
                        >
                            <Send color={!isYetToSend || inProgressEventId ? "disabled" : "primary"} />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
            clone: (
                <Tooltip title="Clone">
                    <span>
                        <IconButton
                            aria-label="clone"
                            size="large"
                            onClick={(e) => handleActionClick(e, handleClone)}
                        >
                            <FileCopy color="primary" />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
            delete: (
                <Tooltip title="Delete">
                    <span>
                        <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={(e) => handleActionClick(e, handleDeleteEvent)}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
        };

        return (
            <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                {buttons.send}
                {buttons.clone}
                {buttons.delete}
            </div>
        );
    };

    return (
        <TableRow key={event._id} onClick={handleRowClick} style={{ cursor: 'pointer' }} hover>
            <NameTableCell>{event.name}</NameTableCell>
            <MessageTableCell title={event.message}>{event.message}</MessageTableCell>
            <DateTableCell>{new Date(event.date).toLocaleDateString()}</DateTableCell>
            <StatusTableCell>{renderStatus()}</StatusTableCell>
            <ActionTableCell onClick={(e) => e.stopPropagation()}>
                {setActionButtons()}
            </ActionTableCell>
        </TableRow>
    );
};

export default EventCard;
