// Imports
import { Grid, withStyles } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { messageShow } from '../../../common/api/actions';
import SectionPaper from '../../../common/SectionPaper';
import styles from './styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import routes from '../../api/routes';
import {leaderboardDetail} from "../api/actions/query";
import PropTypes from "prop-types";
import Checkbox from '@material-ui/core/Checkbox';
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'


// Component
class LeaderboardDetail extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      availableRewards: [],
      redeemedRewards: [],
      offlineDealsRewards: [],
      userData: [],
      walletBalance:""
    }
  }

  componentDidMount() {    
    const {
      match: {
        params: { _id },
      },
    } = this.props;
    this.refresh(_id);
}


refresh = async (_id) => {
  const { leaderboardDetail, messageShow } = this.props;
  try {
    const { data } = await leaderboardDetail({ _id });
    if (data.success && data.data) {
      const { availableRewards, redeemedRewards, offlineDealsRewards, userData , walletBalance} = data.data;
      this.setState({
        availableRewards: availableRewards,
        redeemedRewards: redeemedRewards,
        offlineDealsRewards: offlineDealsRewards,
        userData: userData,
        walletBalance: walletBalance
      });
    }
  } catch (error) {
    messageShow("Some error occurred. Please try again.", error);
  }
};


  

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  back = () => {
        const { history } = this.props;
    
        if (history.length > 2) {
          this.props.history.push(routes.listLeaderboard.path, { activeTab: 'LeaderBoard' });
        } else {
          history.goBack();
        } 
    };


  render() {
    const { classes } = this.props;
    const {availableRewards , redeemedRewards , userData , offlineDealsRewards , walletBalance  } = this.state;

    return (
      <>
      
        <SectionPaper>
         <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" >
             Leaderboard detail
          </Typography>
          {/* <Grid style={{ paddingLeft: '700px',paddingTop:"20px" }}>
      <Typography variant="h6" color="inherit" >
             Leaderboard detail
          </Typography>
        </Grid> */}
          </Toolbar>
         
            {/* <Typography variant="h6" color="inherit" style={{ paddingLeft: '20px',paddingTop:"20px",paddingBottom:"20px" }} >
             Name : Bala
          </Typography> */}

             <TableRow >
                <TableCell>
                  <Typography style={{ fontSize: '16px' }}>Name: {userData.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography style={{ fontSize: '16px' }}>Mobile : {userData.mobile}</Typography>
                </TableCell>
                <TableCell>
                    <Typography style={{ fontSize: '16px' }}>Wallet Balance : {walletBalance}</Typography>
                </TableCell>
             </TableRow>
          

          


          <Container fluid>
            <Row className="mb-10 justify-content-center">
              <Col>
                <Tabs
                  transition={false}
                >
                  <Tab
                    eventKey="Goals"
                    title={<h1 style={{ fontSize: '15px'}}>Offline deals to aprove </h1>}
                    style={{ color: 'blue' }}
                  >
                  <TableHead>
                  <TableRow>
                  <TableCell align="center" width={100}>Earned Points</TableCell>
                  <TableCell align="center" width={100}>Earned values</TableCell>
                  <TableCell align="center" width={200}>Rewards name</TableCell> 
                  <TableCell align="center" width={150}>Offline Deals</TableCell>
                  <TableCell align="center" width={160}>Online Deals Percentage </TableCell> 
                  <TableCell align="center" width={110}>Min order amount</TableCell> 
                  <TableCell align="center" width={140}>Voucher Code</TableCell> 
                  <TableCell align="center" width={140}>Issue Volume</TableCell> 
                  <TableCell align="center" width={140}>Start / Expiry </TableCell> 
                  <TableCell align="center" width={140}>Expiry date </TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                
                {offlineDealsRewards.map(({_id,offlineDeals,onlineDeals,rewardName,earnedPoints,earnedValues,voucherCode,issueVolume,startDate,expiryDate,startTime,endTime }) => (

                
                <TableRow key={_id}>
                {/* <TableCell>
                 <Image
                 src={getImageSource(`${image}`)}
                 defaultSrc={getImageSource()}
                 />             
                </TableCell> */}
                <TableCell align="center">{earnedPoints}</TableCell>
                <TableCell align="center">{earnedValues}</TableCell>
                <TableCell align="center" width={100}>{rewardName}</TableCell>
                <TableCell  align="center">
                <Checkbox
                checked={offlineDeals}
                // disabled
                color="primary"
                />
                </TableCell>
                <TableCell align="center">{onlineDeals.discountPercentage}</TableCell>
                <TableCell align="center">{onlineDeals.minOrderAmount}</TableCell>
                <TableCell align="center">{voucherCode}</TableCell>
                <TableCell align="center">{issueVolume}</TableCell>
                <TableCell align="center">{ dateFormat(dateParseISO(startDate), 'yyyy-MM-dd') } / { dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell align="center">
                {startTime && endTime ? `${dateFormat(dateParseISO(startTime), 'HH:mm')} / ${dateFormat(dateParseISO(endTime), 'HH:mm')}` : ''}
                </TableCell>

                
                
                  </TableRow>
                ))}

                </TableBody>
                 
                  </Tab>
                  <Tab
                    eventKey="Points"
                    title={<h1 style={{ fontSize: '15px' }}>Available deals</h1>}
                  >
                <TableHead>
                  <TableRow>
                  <TableCell align="center" width={100}>Earned Points</TableCell>
                  <TableCell align="center" width={100}>Earned values</TableCell>
                  <TableCell align="center" width={200}>Rewards name</TableCell> 
                  <TableCell align="center" width={150}>Offline Deals</TableCell>
                  <TableCell align="center" width={160}>Online Deals Percentage </TableCell> 
                  <TableCell align="center" width={110}>Min order amount</TableCell> 
                  <TableCell align="center" width={140}>Voucher Code</TableCell> 
                  <TableCell align="center" width={140}>Issue Volume</TableCell> 
                  <TableCell align="center" width={140}>Start / Expiry </TableCell> 
                  <TableCell align="center" width={140}>Expiry date </TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                
                {availableRewards.map(({_id,offlineDeals,onlineDeals,rewardName,earnedPoints,earnedValues,voucherCode,issueVolume,startDate,expiryDate,startTime,endTime }) => (

                
                <TableRow key={_id}>
                
                <TableCell align="center">{earnedPoints}</TableCell>
                <TableCell align="center">{earnedValues}</TableCell>
                <TableCell align="center" width={100}>{rewardName}</TableCell>
                <TableCell  align="center">
                <Checkbox
                checked={offlineDeals}
                // disabled
                color="primary"
                />
                </TableCell>
                <TableCell align="center">{onlineDeals.discountPercentage}</TableCell>
                <TableCell align="center">{onlineDeals.minOrderAmount}</TableCell>
                <TableCell align="center">{voucherCode}</TableCell>
                <TableCell align="center">{issueVolume}</TableCell>
                <TableCell align="center">{ dateFormat(dateParseISO(startDate), 'yyyy-MM-dd') } / { dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell align="center">
                {startTime && endTime ? `${dateFormat(dateParseISO(startTime), 'HH:mm')} / ${dateFormat(dateParseISO(endTime), 'HH:mm')}` : ''}
                </TableCell>

                
                
                  </TableRow>
                ))}

                </TableBody>
                
                  </Tab>
                  <Tab
                    eventKey="Rewards"
                    title={<h1 style={{ fontSize: '15px' }}>Redeemed online deals</h1>}
                  >
                  <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 220 }} align="center">Reward Name</TableCell>
                    <TableCell style={{ width: 170 }} align="center">Earned Points</TableCell>
                    <TableCell style={{ width: 230 }} align="center">Used Date</TableCell>
                    <TableCell style={{ width: 230 }} align="center">Used Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                
                {redeemedRewards.map(({earnedPoints,rewardName,rewardsId,usedAt }) => (
                <TableRow key={rewardsId}>
               
                <TableCell align="center" width={100}>{rewardName}</TableCell>
                <TableCell align="center">{earnedPoints}</TableCell>
                <TableCell align="center">{ dateFormat(dateParseISO(usedAt), 'yyyy-MM-dd') } </TableCell>
                <TableCell align="center">
                {usedAt ? `${dateFormat(dateParseISO(usedAt), 'HH:mm')}` : ''}
                </TableCell>
                </TableRow>
                ))}
                </TableBody>
                </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        
          
        </SectionPaper>
        </>
    );
  }
}


LeaderboardDetail.propTypes = {
  leaderboardDetail:PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(null, { messageShow,leaderboardDetail })(withStyles(styles)(LeaderboardDetail));
