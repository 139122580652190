// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'

// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from "@mui/material/Checkbox"
import Button from '@mui/material/Button'
import { withStyles } from '@material-ui/core'
import styles from './styles'

// App Imports
import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import Loading from '../../common/Loading'
import EmptyMessage from '../../common/EmptyMessage'
import Pagination from '../../common/Pagination'
import Image from '../../common/Image'
import { listVendors } from '../../user/api/actions/query'
import { publishToggleVendor, removeVendor } from '../../user/api/actions/mutation'
import { imageUser } from '../../user/api/routes'
import routes from '../api/routes'
import { getImageSource } from '../../user/api/routes'

// Component
class List extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      vendors: [],
      count: 0
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps

    this.get(match.params.page)
  }

  refresh = (isLoading = true) => {
    const { match } = this.props

    this.get(match.params.page, isLoading)
  }

  get = async (page = 1, isLoading = true) => {
    const { listVendors, messageShow } = this.props

    this.isLoadingToggle(isLoading)

    try {
      const { data } = await listVendors({ page })

      if(data.success) {
        this.setState({
          vendors: data.data.list,
          count: data.data.count
        })
      } else {
        messageShow(data.message)
      }
    } catch(error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  onPublishToggle = vendorId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${ isPublished ? 'publish' : 'unpublish' } this vendor?`)

    if(check) {
      const { publishToggleVendor, messageShow } = this.props

      try {
        const { data } = await publishToggleVendor({ vendorId, isPublished })

        messageShow(data.message)

        if(data.success) {
          this.refresh(false)
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onDelete = (vendorId) => async () => {
    let check = window.confirm('Are you sure you want to delete this vendor?')

    if(check) {
      const { removeVendor, messageShow } = this.props

      try {
        const { data } = await removeVendor({ vendorId })

        messageShow(data.message)

        if(data.success) {
          this.refresh(false)
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }



  render() {
    const { classes } = this.props
    const { isLoading, vendors, count } = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Vendors
          </Typography>

          <Button onClick={this.refresh}>
            Refresh
          </Button>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : vendors.length === 0
                ? <EmptyMessage message={'No vendors to show.'} />
                : <>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={85}>Image</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell width={130}>Mobile</TableCell>
                          <TableCell width={180}>Joined</TableCell>
                          <TableCell width={80} align="center">Published</TableCell>
                          <TableCell width={90} align="center">Actions</TableCell>
                          <TableCell width={90} align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          vendors.map(({ _id, image, name, mobile, email, isPublished, createdAt }) =>
                            <TableRow key={_id}>
                              <TableCell>
                                <Link to={routes.vendorUpdate.path(_id)}>
                                <Image
                                  src={getImageSource(`${image}`)}
                                  defaultSrc={getImageSource()}
                                />
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link to={routes.vendorUpdate.path(_id)}>
                                  {name}
                                </Link>
                              </TableCell>
                              <TableCell>{ email }</TableCell>
                              <TableCell>{ mobile }</TableCell>
                              <TableCell>{ dateFormat(dateParseISO(createdAt), params.date.format.full) }</TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={isPublished}
                                  onChange={this.onPublishToggle(_id)}
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Button color="error" onClick={this.onDelete(_id)}>Delete</Button>
                              </TableCell>
                              <TableCell align="center">
                                <Button href={`/settings/${_id}`}>Setting</Button>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>

                    <Pagination
                      count={count}
                      route={routes.vendorList}
                    />
                  </>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  listVendors: PropTypes.func.isRequired,
  publishToggleVendor: PropTypes.func.isRequired,
  removeVendor: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default connect(null, { listVendors, publishToggleVendor, removeVendor, messageShow })(withStyles(styles)(List))
