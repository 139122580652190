// Imports
import Button from "@mui/material/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import * as _ from "lodash";
// UI Imports
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import IconClose from "@material-ui/icons/Close";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// App Imports
import params from "../../../setup/config/params";
import {
  capitalizeFirstLetter,
  nullToEmptyString,
  slug,
  subString,
} from "../../../setup/helpers";
import { list as listCategories } from "../../category/api/actions/query";
import { messageShow } from "../../common/api/actions";
import EmptyMessage from "../../common/EmptyMessage";
import Image from "../../common/Image";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import SectionPaper from "../../common/SectionPaper";
import { listVendorsAll } from "../../user/api/actions/query";
import { createOrUpdate } from "../api/actions/mutation";
import { detail } from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import CSVToArray from "./csv_parser";
import styles from "./styles";

if (!Object.allWith) {
  /*  Non writeable enumerable configurable property of Object.prototype
      as a function in the form
      Object.allWith(predicate)
      Arguments
          predicate Function used to test the child property takes the argument
             obj the current object to test
          and will return true if the condition is meet
      Return
          An array of all objects that satisfy the predicate

      Example

      var test = {a : { key : 10, data: 100}, b : { key : 11, data: 100} };
      var res = test.allWith((obj)=>obj.key === 10);
      // res contains test.a
  */
  /*StackOverflow solution https://stackoverflow.com/questions/42252538/deep-search-json-object*/
  Object.defineProperty(Object.prototype, "allWith", {
    writable: false,
    enumerable: false,
    configurable: false,
    value: function (predicate) {
      var uObjects = [];
      var objects = [];
      if (typeof predicate !== "function") {
        throw new TypeError("predicate is not a function");
      }
      (function find(obj) {
        var key;
        if (predicate(obj) === true) {
          objects.push(obj);
        }
        for (key of Object.keys(obj)) {
          let o = obj[key];
          if (o && typeof o === "object") {
            if (!uObjects.find((obj) => obj === o)) {
              uObjects.push(o);
              find(o);
            }
          }
        }
      })(this);
      return objects;
    },
  });
} else {
  console.warn("Warn!! Object.allWith already defined.");
}

// Component
class BulkCreate extends Component {
  constructor(props) {
    super(props);

    this.product = {
      _id: "",
      vendorId: "",
      categoryId: "",
      name: "",
      slug: "",
      brand: "",
      description: "",
      price: "",
      storePrice:"",
      VegNonVeg:"",
      priceDiscount: "",
      priceDiscounted: "",
      unit: "",
      image: "default.jpg",
      gallery: [],
      canSubscribe: true,
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      loader: false,
      rowsPerPage: 5,
      page: 0,
      setPage: 0,
      open: false,

      vendors: [],
      products: [],
      product: this.product,
    };
  }

  componentDidMount() {
    const {
      auth: { user },
      match: {
        params: { productId },
      },
    } = this.props;

    // Get categories
    this.getCategories();

    // Get vendors
    if (user.role === params.user.roles.vendor.key) {
      const { product } = this.state;
      product.vendorId = user._id;
      this.setState({
        product,
      });
    } else {
      this.getVendors();
    }

    // Get product for editing
    if (productId) {
      this.getProduct(productId);
    }
  }

  getVendors = async () => {
    const { listVendorsAll, messageShow } = this.props;

    try {
      const { data } = await listVendorsAll();

      if (data.success) {
        this.setState({
          vendors: data.data,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };

  getCategories = () => {
    const { listCategories } = this.props;
    listCategories();
  };

  getProduct = async (productId) => {
    const { detail, messageShow } = this.props;

    this.isLoadingToggle(true);
    try {
      const { data } = await detail({ productId });
      if (data.success) {
        let product = data.data;
        product.vendorId = product.vendorId._id;
        product.categoryId = product.categoryId._id;

        this.setState({
          product,
        });
      }
    } catch (error) {
      messageShow("There was some error. Please try again");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  createProduct = async (pidx, delItem = true) => {
    const { createOrUpdate, messageShow } = this.props;
    const { products } = this.state;
    const product = Object.assign({}, products[pidx]);
    const categoryId = product.categoryId._id;

    product.categoryId = categoryId;
    this.isLoadingSubmitToggle(true);
    try {
      const { data } = await createOrUpdate({ product });
      this.isLoadingSubmitToggle(false);
      messageShow(data.message);
      if (data.success && delItem) {
        this.deleteProduct(pidx);
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);
      messageShow("Some error occurred. Please try again.");
    }
  };

  onCreateAll = async (event) => {
    event.preventDefault();
    const { products } = this.state;
    products.forEach((_, i) => {
      this.createProduct(i, false);
      // Empty products
      this.setState({ products: [] });
    });
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onType = ({ target: { name, value } }) => {
    const { product } = this.state;

    if (name === "name") {
      product.slug = slug(value);
    }

    product[name] = name === "slug" ? value : capitalizeFirstLetter(value);

    if (name === "price" || name === "priceDiscount") {
      product.priceDiscounted =
        product.priceDiscount !== ""
          ? (
              product.price -
              product.price * (product.priceDiscount / 100)
            ).toFixed(2)
          : product.price;
    }

    this.setState({
      product,
    });
  };

  onSelect = ({ target: { name, value } }) => {
    const { product } = this.state;

    product[name] = value;
    this.setState({
      product,
    });
    this.isUploadingFileToggle(false);
  };

  getCategoryById = async (categorySlug) => {
    const { categories } = this.props;
    let res = null;
    categories.list.map((obj) => {
      if (obj.category.slug === categorySlug) {
        res = obj;
        return true;
      } else {
        if (obj.subCategories?.length > 0) {
          obj.subCategories.map((eSub) => {
            if (eSub.slug === categorySlug) {
              res = eSub;
              return true;
            }
          });
        }
      }
    });
    return res;
  };

  onVendorCheck = async () => {
    const { messageShow } = this.props;
    const { product } = this.state;

    if (product.vendorId === "" || product.vendorId === null) {
      this.isUploadingFileToggle(true);
      messageShow("Please Select Vendor.");
    } else {
      this.onUpload();
    }
  };

  onUpload = async (event) => {
    this.setState({
      loader: true,
      products: [],
      open: true,
    });
    const {
      auth: { user },
      messageShow,
    } = this.props;
    const fileContent = event.target.files[0];
    var reader = new FileReader();
    reader.onload = async (e) => {
      this.setState({ loader: false });
      const data = CSVToArray(reader.result);
      let headers = data.shift(); // pluck first item from
      let finalResult = [];
      await data.map(async (eachItem) => {
        if (eachItem.length === headers.length) {
          const eachObj = _.zipObject(headers, eachItem);
          const categoryData = await this.getCategoryById(
            eachObj["category slug"]
          );
          if (categoryData?._id) {
            eachObj.categoryId = categoryData;
            eachObj.mode = 'bulkcreate';
            finalResult.push(eachObj);
          }
        }
      });
      this.setState({ products: finalResult });
      this.textInput.value = "";
      this.isUploadingFileToggle(false);
    };
    reader.onerror = (error) => {
      messageShow("There was some error. Please try again.");
      this.isUploadingFileToggle(false);
    };
    messageShow("Uploading file, please wait...");
    reader.readAsText(fileContent);

    this.isUploadingFileToggle(true);
  };

  deleteProduct = (pidx) => {
    const { products } = this.state;
    products.splice(pidx, 1);
    this.setState({ products });
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.productList.path(1));
    }
  };

  onChangeCanSubscribe = (event) => {
    const { product } = this.state;

    product.canSubscribe = event.target.checked;

    this.setState({
      product,
    });
  };

  // Pegination

  tablePaginationActions = (props) => {
    const handleBackButtonClick = (event) => {
      this.handleChangePage(event, this.state.page - 1);
    };

    const handleNextButtonClick = (event) => {
      this.handleChangePage(event, this.state.page + 1);
    };
    return (
      <div style={{ width: "200px" }}>
        <Grid container justifyContent="space-between">
          <IconButton
            onClick={handleBackButtonClick}
            disabled={this.state.page === 0}
            aria-label="Previous Page"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={
              this.state.page >=
              Math.ceil(this.count / this.state.rowsPerPage) - 1
            }
            aria-label="Next Page"
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
      </div>
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 0) });
  };

  render() {
    const {
      auth: { user },
      match: {
        params: { productId },
      },
      classes,
    } = this.props;
    const { products, isLoading, isUploadingFile, count, vendors, product } =
      this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {productId ? "Edit" : "Create"} Bulk Products
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {isLoading ? (
              <Loading />
            ) : (
              <form>
                {this.state.loader ? (
                  <Modal
                    open={this.state.open}
                    style={{ backgroundColor: "rgba(0, 0, 0, .9)" }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ height: "100vh" }}
                    >
                      <CircularProgress className={classes.progress} />
                    </Grid>
                  </Modal>
                ) : null}
                <Grid container className={classes.buttonUpload}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.grow}
                  >
                    Choose Vendor for Adding New Product
                  </Typography>
                  {user.role === params.user.roles.admin.key && (
                    <FormControl
                      fullWidth
                      className={classes.select}
                      // required={true}
                    >
                      <InputLabel htmlFor="vendorId">Vendor</InputLabel>
                      <Select
                        value={nullToEmptyString(product.vendorId)}
                        onChange={this.onSelect}
                        inputProps={{
                          name: "vendorId",
                          id: "vendorId",
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {vendors.length > 0 &&
                          vendors.map((item) => (
                            <MenuItem
                              key={item._id}
                              value={item._id}
                            >{`${item.name} (${item.email})`}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}

                  <Grid item sm={6}>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <input
                        accept={".csv"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                        ref={(ref) => (this.textInput = ref)}
                      />
                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          disabled={isUploadingFile}
                          fullWidth
                        >
                          Upload CSV file
                        </Button>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </SectionPaper>
        </Grid>
        <Grid item xs={12}>
          <SectionPaper>
            {isLoading ? (
              <Loading />
            ) : products.length === 0 ? (
              <EmptyMessage message={"You have not added any products yet."} />
            ) : (
              <>
                <Table padding={"normal"}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={85}>Image</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Buys</TableCell>
                      {/* <TableCell>Subscribable</TableCell> */}
                      <TableCell
                        width={
                          user.role === params.user.roles.admin.key ? 180 : 90
                        }
                        align="center"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map(
                        (
                          { image, name, categoryId, buyCount, canSubscribe },
                          i
                        ) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Image
                                src={getImageSource(image)}
                                defaultSrc={getImageSource()}
                              />
                            </TableCell>
                            <TableCell>{subString(name, 30)}</TableCell>
                            <TableCell>{categoryId.name}</TableCell>
                            <TableCell>{buyCount || 0}</TableCell>
                            <TableCell>
                              {/* Button - Save */}
                              <Grid
                                item
                                xs={12}
                                className={classes.buttonsContainer}
                              >
                                <IconButton
                                  type={"button"}
                                  aria-label={"Close"}
                                  color={"secondary"}
                                  onClick={() => this.deleteProduct(i)}
                                >
                                  <IconClose />
                                </IconButton>
                                <IconButton
                                  type={"button"}
                                  aria-label={"Save"}
                                  color={"primary"}
                                  onClick={() => this.createProduct(i)}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        count={products.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={this.tablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
                <Pagination count={10} route={routes.productList} />
                {/* <Grid
                  item
                  justifyContent="flex-end"
                  spacing={5}
                  style={{ paddingBlock: 12, textAlign: "center" }}
                >
                  <Button
                    onClick={this.onCreateAll}
                    variant="contained"
                    size="medium"
                    disabled={isUploadingFile}
                  >
                    Save all
                  </Button>
                </Grid> */}
              </>
            )}
          </SectionPaper>
        </Grid>
      </div>
    );
  }
}

// Component Properties
BulkCreate.propTypes = {
  auth: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  listVendorsAll: PropTypes.func.isRequired,
  listCategories: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function bulkCreateState(state) {
  return {
    auth: state.auth,
    categories: state.categories,
  };
}

export default connect(bulkCreateState, {
  detail,
  createOrUpdate,
  listVendorsAll,
  listCategories,
  messageShow,
})(withStyles(styles)(BulkCreate));
