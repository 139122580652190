// Imports
import React, { PureComponent } from 'react'
import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UI Import
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@mui/icons-material/Check';
import IconCheckCircle from '@material-ui/icons/CheckCircle'
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

// App Imports
import { nullToEmptyString } from '../../../setup/helpers'
import { messageShow } from '../../common/api/actions'
import { checkMail } from '../api/actions/query'
import { sendMail } from '../api/actions/mutation'

class ForgotPassword extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      email: '',

      isSubmitting: false,
      isSubmitted: false,
      loader: true,
      isVerified: false
    }
  }

  onType = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  sendEmailId = async event => {
    event.preventDefault()
    const { checkMail, messageShow } = this.props
    const { email, isVerified } = this.state

    if (isVerified) {
      const status = await checkMail({ email })
      if (status.success) {
        this.setState({
          isSubmitted: true
        })
      }
    } else {
      messageShow('Please verified the capcha')
    }

    
  }

  resetPassword = async () => {
    const { sendMail, messageShow } = this.props
    const { email } = this.state

    const mailSend = await sendMail({ email })

    if (mailSend.data.success) {
      this.setState({
        loader: false
      })
      messageShow('Mail send Successfully')
    } else {
      this.setState({
        loader: true
      })
      messageShow('Sorry!! Please enter Admin/Vendor mail id')
    }
  }

  recapchaLoaded = () => {
    this.setState({ isVerified: true })
  }

  render() {
    const { email, isSubmitted, isSubmitting, loader } = this.state
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Paper className={classes.container}>
          {
            isSubmitted
            ? <div>
                <div style={{ marginBottom: '10px'}}>
                  {
                    loader ?
                    <div>
                      <Typography
                        variant={'subtitle1'}
                        color={'inherit'}
                        className={classes.heading}
                      >
                        Click here to send verfication mail
                      </Typography>
                      <IconCheckCircle color="primary" fontSize="large" />
                    </div>
                  : <Typography
                      variant={'subtitle1'}
                      color={'inherit'}
                      className={classes.heading}>
                      An email with password reset link has been sent to your email id.
                      <br />
                      Please check your mail.
                    </Typography>
                  }
                </div>
                <Button variant="contained" color="secondary" className={classes.button} onClick={this.resetPassword}>
                  Reset Password
                </Button>
              </div>
          : <div>
              <Typography
                variant={'subtitle1'}
                color={'inherit'}
                className={classes.heading}
              >
                Please enter registered mail id
              </Typography>
            <form onSubmit={this.sendEmailId}>
              {/* Input - email */}
              <Grid item xs={12}>
                <TextField
                  name={'email'}
                  type={'email'}
                  value={nullToEmptyString(email)}
                  onChange={this.onType}
                  label={'Email'}
                  placeholder={'Enter email'}
                  required={true}
                  margin={'dense'}
                  autoComplete={'off'}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LeU-5gpAAAAAKIVKoagvGxf_MJrnncLylmbCyEx"
                >
                  <GoogleReCaptcha onVerify={this.recapchaLoaded} />
                </GoogleReCaptchaProvider>
              </Grid>

              {/* Button -  Save */}
              <Grid item xs={12} className={classes.buttonsContainer}>
                <IconButton
                  type={'submit'}
                  aria-label={'Save'}
                  color={'primary'}
                  disabled={isSubmitting}
                >
                  <CheckIcon/>
                </IconButton>
              </Grid>
            </form>
          </div>
          }
        </Paper>
      </div>
    )
  }
}

// Component Properties
ForgotPassword.propTypes = {
  checkMail: PropTypes.func.isRequired,
  sendMail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  messageShow: PropTypes.func.isRequired
}

export default connect(null, { checkMail, sendMail, messageShow })(withStyles(styles)(ForgotPassword))
