import axios from 'axios'

// App Imports
import { API_URL } from '../../../../../setup/config/env'

// Actions

export function getAllEarnedPoints() {
    return dispatch => {
    return axios.get(API_URL+"/api/earnedPoints/getAllEarnedPoints")
}
}

export function leaderboardDetail({_id}) {
    return dispatch => {
    return axios.get(`${API_URL}/api/earnedPoints/leaderboardDetailById/${_id}`)
}
}
