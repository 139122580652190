import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getAllFaqs } from "./api/actions/query";
import { addFaq, updateFaq, deleteFaq } from "./api/actions/mutation";
import { messageShow } from "../common/api/actions";
import styles from "./styles";
import { Button, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";

class FaqManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      newPrompt: "",
      newAnswer: "",
      editStates: {},
    };
  }

  componentDidMount() {
    this.fetchFaqs();
  }

  fetchFaqs = async () => {
    const { getAllFaqs } = this.props;
    try {
      const { data } = await getAllFaqs();
      if (data.success) {
        this.setState({ faqs: data.data });
      }
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this FAQ?")) {
      this.props.deleteFaq({ id }).then(() => {
        this.props.messageShow("FAQ deleted successfully.");
        this.fetchFaqs();
      });
    }
  };

  handleAdd = () => {
    const { newPrompt, newAnswer } = this.state;
    if (newPrompt.trim() !== "" && newAnswer.trim() !== "") {
      this.props.addFaq({ prompt: newPrompt, answer: newAnswer }).then(() => {
        this.props.messageShow("FAQ added successfully.");
        this.fetchFaqs();
      });
      this.setState({ newPrompt: "", newAnswer: "" });
    } else {
      this.props.messageShow("Prompt and Answer cannot be empty.");
    }
  };

  handleUpdate = async () => {
    const { editStates } = this.state;
    updateFaq(editStates);
    this.fetchFaqs();
    this.setState({ editStates: {} });
  };

  render() {
    const { classes } = this.props;
    const { faqs, newPrompt, newAnswer, editStates } = this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" className={classes.grow}>
            Faq Management
          </Typography>
        </Toolbar>
        <div style={{ padding: "18px" }}>
          <div style={{ paddingBottom: "18px" }}>
            <Typography variant="h5" style={{ paddingBottom: "18px" }}>
              Add New FAQ
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <TextField
                  type="text"
                  placeholder="Enter prompt"
                  value={newPrompt}
                  onChange={(e) => this.setState({ newPrompt: e.target.value })}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  placeholder="Enter answer"
                  value={newAnswer}
                  onChange={(e) => this.setState({ newAnswer: e.target.value })}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button color="primary" size="medium" onClick={this.handleAdd}>
                  Add FAQ
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="h5" style={{ paddingBottom: "18px" }}>
              Edit FAQ
            </Typography>
            {faqs.map((faq) => (
              <Grid container spacing={2} alignItems="center" key={faq._id}>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    value={
                      editStates[faq._id]?.prompt !== undefined &&
                      editStates[faq._id]?.prompt !== null
                        ? editStates[faq._id]?.prompt
                        : faq.prompt
                    }
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        editStates: {
                          ...prevState.editStates,
                          [faq._id]: {
                            ...prevState.editStates[faq._id],
                            prompt: e.target.value,
                          },
                        },
                      }))
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="text"
                    value={
                      editStates[faq._id]?.answer !== undefined &&
                      editStates[faq._id]?.answer !== null
                        ? editStates[faq._id]?.answer
                        : faq.answer
                    }
                    onChange={(e) =>
                      this.setState((prevState) => ({
                        editStates: {
                          ...prevState.editStates,
                          [faq._id]: {
                            ...prevState.editStates[faq._id],
                            answer: e.target.value,
                          },
                        },
                      }))
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    size="medium"
                    onClick={() => this.handleDelete(faq._id)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={this.handleUpdate}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

FaqManagement.propTypes = {
  faqs: PropTypes.array.isRequired,
  getAllFaqs: PropTypes.func.isRequired,
  addFaq: PropTypes.func.isRequired,
  updateFaq: PropTypes.func.isRequired,
  deleteFaq: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    faqs: state.faqs,
  };
}

export default connect(mapStateToProps, {
  getAllFaqs,
  addFaq,
  updateFaq,
  deleteFaq,
  messageShow,
})(withStyles(styles)(FaqManagement));
