import params from "../../../setup/config/params";
import { IMAGE_SOURCE_PATH } from "../../../setup/config/env";
import Analytics from "../index";

export function getImageSource(image = "/default.jpg") {
    return `${IMAGE_SOURCE_PATH}${image}`;
}

export default {
    analytics: {
        path: `/analytics`,
        component: Analytics,
        auth: true,
        role: params.user.roles.admin.key,
    }
};
