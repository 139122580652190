import axios from "axios";
import { messageShow } from "../../../common/api/actions";
import { API_URL } from "../../../../setup/config/env";

export const feedbackReply = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/api/rating/reply`, payload);
    if (response.data.success) {
      return response.data;
    } else {
      messageShow(response.data.message || "Failed to reply to feedback.");
      return response.data;
    }
  } catch (error) {
    console.error("Error replying to feedback:", error);
    messageShow("An error occurred while replying to feedback.");
  }
};
