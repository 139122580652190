// src/api/delivery.js
import axios from "axios";
import { API_URL } from "../../../../setup/config/env";

// Get detail
export const getOrderDetails = async (orderId) => {
  try {
    const response = await axios.post(API_URL, {
      operation: "orderDetail",
      params: { orderId },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating quote:", error);
    throw error;
  }
};

export function createQuote(params) {
  return axios.post(API_URL, {
    operation: "uberCreateQuote",
    params,
  });
}

export function createDelivery(params) {
  return axios.post(API_URL, {
    operation: "uberCreateDelivery",
    params,
  });
}

export function vendorDetails() {
  return axios.post(API_URL, {
    operation: "uberVendorDetail",
  });
}

export function getUberDeliveryDetails(orderId) {
  return axios.post(API_URL, {
    operation: "uberDeliveryDetail",
    params: { orderId },
  });
}

export function getDeliveryDetails(deliveryId) {
  return axios.post(API_URL, {
    operation: "uberGetDelivery",
    params: { deliveryId },
  });
}
