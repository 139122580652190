// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Save
export function save(settings) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'settingSave',
      params: settings
    })
  }
}

// set resume orders
export function setResumeOrder(resumeOrder) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'setResumeOrder',
      params: resumeOrder
    })
  }
}

export function updateSettings({vendorId,requestObject}) {
  return dispatch => {
    return axios.post(`${API_URL}/api/setting/update`, {
      "vendorId":vendorId,
      requestObject
    })
  }
}
