// Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import ImageComp from '../../common/Image';
import FileUpload from '@mui/icons-material/FileUpload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { messageShow,upload } from '../../common/api/actions';
import SectionPaper from '../../common/SectionPaper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import routes from '../api/routes';
import axios from 'axios';
import styles from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from "dayjs";
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import PropTypes from "prop-types";
import { createRewards} from "../apiRewards/actions/mutation";
import { getImageSource } from "../apiRewards/routes";
import { getPoints } from "../../rewards/apiPoints/actions/query";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

// Component
class Createrewards extends Component {
  constructor(props) {
    super(props);
  
    this.rewards = {
      rewardName: "",
      earnedPoints:"",
      earnedValues:"",
      offlineDeals:false,
      onlineDeals:{
        discountPercentage:"",
        minOrderAmount:"",
        maxDiscountAmount:""
      },
      voucherCode:"",
      issueVolume:"",
      startDate: dayjs(),
      expiryDate: dayjs(),
      startTime: dayjs().hour(0).minute(0),
      endTime: dayjs().hour(0).minute(0),
      image: "default.jpg",
    };
  
    this.state = {
      dynamicPoints:'',
      dynamicValues:'',
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      isLoadingSubmitToggle:false,
      rewards: this.rewards,
      previewImage: null,
    };
  }

  async componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { getPoints, messageShow } = this.props;
  
    this.isLoadingToggle(true);
  
    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          dynamicPoints: data.data[0].points,
          dynamicValues:data.data[0].value
        });

      } 
    } catch (error) {
      // console.log( error);
      messageShow("Some error occurred. Please try again.",error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  onCreate = async (event) => {
    event.preventDefault();

    const {  createRewards, messageShow,history} = this.props;

    const { rewards } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createRewards(rewards);
      // messageShow(data.message);
      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
      console.log("error createRewards",error)
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  onType = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [name]: value
      }
    }));
  }
  onTypeIssueVolume = (event) => {
    const { name, value } = event.target;

    // Allow only numbers
    if (!/^\d*$/.test(value)) {
        return; // If value is not a number, do not update the state
    }

    this.setState((prevState) => ({
        rewards: {
            ...prevState.rewards,
            [name]: value
        }
    }));
};

  onTypee = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split('.');

    
    if (!/^\d*$/.test(value)) {
        return; 
    }

    this.setState((prevState) => ({
        rewards: {
            ...prevState.rewards,
            [category]: {
                ...prevState.rewards[category],
                [field]: value
            }
        }
    }));
};


  onUpload = async (event) => {
    const { upload, messageShow } = this.props;
    messageShow("Uploading file, please wait...");
    this.isUploadingFileToggle(true);
    const imgFile = event.target.files[0];
    let file = new FormData();
    file.append("type", "rewards");
    file.append("name", this.state.rewards.rewardName);
    file.append("file", imgFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);
    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        
       this.props.messageShow("File uploaded successfully.");
        const { rewards } = this.state;
        setTimeout(() => {
          rewards.image = data.file;
          this.setState({
            rewards,
          });
        }, 1000);
      }else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };
  
  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
    this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
    } else {
      history.goBack();
    }
  };
  handleDateChange = (key) => (date) => {
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [key]: date,
      },
    }));
  };

  handleTimeChange = (time, field) => {
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [field]: time,
      }
    }));
  };

  handleEarnedPointsChange = (event) => {
    const points = parseFloat(event.target.value);
    if (!isNaN(points)) {
      const { dynamicPoints, dynamicValues } = this.state;
      const calculatedValue = (points * dynamicValues) / dynamicPoints;
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: points,
          earnedValues: calculatedValue.toFixed(2),
        }
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: '',
          earnedValues: '',
        }
      });
    }
  };

  onChangeOfflineDeals = (event) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        offlineDeals: event.target.checked,
      },
    });
  };

  render() {
    const {classes} = this.props;
    const { rewards,isLoadingSubmit } =
      this.state;
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" >
             Create Rewards
          </Typography>
        </Toolbar>
          <Toolbar className={classes.toolbar}>
           <Grid item xs={12} lg={6}>
           <SectionPaper padding>
      <form onSubmit={this.onCreate}>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
        <TextField
            name="rewardName"
            value={nullToEmptyString(rewards.rewardName)}
            variant="standard"
            onChange={this.onType}
            label="Reward Name"
            placeholder="Enter reward name"
            required={true}
            margin="dense"
            autoComplete="off"
            fullWidth
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} style={{ marginBottom: 12 }}>
                  <TextField
                    name="earnedPoints"
                    value={nullToEmptyString(rewards.earnedPoints)}
                    variant="standard"
                    onChange={this.handleEarnedPointsChange}
                    label="Earned Points"
                    placeholder="Enter Earned Points"
                    required={true}
                    margin="dense"
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 12 }}>
                <TextField
                  name="earnedValues"
                  value={rewards.earnedValues}
                  variant="standard"
                  label="Event Earned Values"
                  placeholder="Enter Earned Values"
                  required={true}
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  disabled
                />
                </Grid>
         </Grid>


                <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.discountPercentage"}
                    value={nullToEmptyString(this.state.rewards.onlineDeals.discountPercentage)}
                    variant="standard"
                    onChange={this.onTypee}
                    label={"Discount Percentage"}
                    placeholder={"Enter Discount Percentage"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"voucherCode"}
                    value={nullToEmptyString(rewards.voucherCode)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Voucher Code"}
                    placeholder={"Enter Voucher Code"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.minOrderAmount"}
                    value={nullToEmptyString(this.state.rewards.onlineDeals.minOrderAmount)}
                    variant="standard"
                    onChange={this.onTypee}
                    label={"Min Order Amount"}
                    placeholder={"Enter Min Order Amount"}
                    // required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.maxDiscountAmount"}
                    value={nullToEmptyString(this.state.rewards.onlineDeals.maxDiscountAmount)}
                    variant="standard"
                    onChange={this.onTypee}
                    label={"Max Discount Amount"}
                    placeholder={"Enter Max Discount Amount"}
                    // required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"issueVolume"}
                    value={nullToEmptyString(this.state.rewards.issueVolume)}
                    variant="standard"
                    onChange={this.onTypeIssueVolume}
                    label={"Issue Volume"}
                    placeholder={"Enter Issue Volume"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
               <Grid item xs={6} style={{ marginBottom: 24 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.rewards.offlineDeals}
                      onChange={this.onChangeOfflineDeals}
                      name="offlineDeals"
                      color="primary"
                      margin="normal"
                      autoComplete="off"
                      fullWidth
                    />
                  }
                  label="Offline Deal"
                />
              </Grid>

                </Grid>


                <Grid container spacing={3}>
                <Grid item xs={6} >
                <DatePicker
                    required
                    label="Start Date"
                    value={dayjs(rewards.startDate)}
                    slotProps={{textField:{variant:"standard" }}}
                    onChange={this.handleDateChange('startDate')}
                  />
                </Grid>
                <Grid item xs={6} >
                  <DatePicker
                    required
                    value={dayjs(rewards.expiryDate)}
                    label="Expiry Date"
                    slotProps={{ textField: { variant: "standard" } }}
                    onChange={this.handleDateChange('expiryDate')}
                  />
                </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                <Grid item xs={6}>
                  <TimePicker
                    required
                    value={rewards.startTime}
                    label="Start Time"
                    inputVariant="standard"
                    onChange={(time) => this.handleTimeChange(time, 'startTime')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    required
                    value={rewards.endTime}
                    label="End Time"
                    inputVariant="standard"
                    onChange={(time) => this.handleTimeChange(time, 'endTime')}
                  />
                </Grid>
              </Grid>
               <Grid item xs={12} container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                <Grid
                  container
                  spacing={8}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6} >
                      {!this.state.previewImage ? (
                        <a
                          href={getImageSource(rewards.image)}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <ImageComp
                            src={getImageSource(`${rewards.image}`)}
                            defaultSrc={getImageSource()}
                            size={"100%"}
                          />
                        </a>
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_rewards"
                        ></img>
                      )}
                      </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        variant={"outlined"}
                        component={"span"}
                        type={"file"}
                        fullWidth
                      >
                        <FileUpload
                          fontSize="16"
                          style={{ marginInlineEnd: 16 }}
                        />
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
        <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              type={"button"}
              aria-label={"Close"}
              variant="outlined"
              color="secondary"
              onClick={this.back}
            >
              Cancel
            </Button>
          <Button
            style={{ marginInlineStart: 12 }}
            type={"submit"}
            aria-label={"Save"}
            color={"primary"}
            variant="contained"
            disabled={isLoadingSubmit}
          >
            Save
          </Button>
        </Grid>
      </form>
    
  </SectionPaper>
</Grid>
      </Toolbar>
      
      </SectionPaper>
    )
  }
}
Createrewards.propTypes = {
  
  createRewards: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getPoints: PropTypes.object.isRequired,
};
function createOrEditState(state) {
  return {
    rewards: state.rewards,
  };
}

export default connect(createOrEditState, {
  createRewards,
  getPoints,
  messageShow,
  upload,
})(withStyles(styles)(Createrewards));


