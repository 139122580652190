// Imports
import { Grid, withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Table from "@material-ui/core/Table";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import EmptyMessage from "../../common/EmptyMessage";
import { remove } from '../apiRewards/actions/mutation'

import routes from '../api/routes'

import styles from './styles'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import {listrewards} from "../apiRewards/actions/query";
import { getImageSource } from '../apiRewards/routes'
import Image from '../../common/Image'
import Checkbox from '@material-ui/core/Checkbox';


// Component
class Rewards extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      Rewards: [],
      count: 0
    }
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const { listrewards,isLoading } = this.props;
    this.isLoadingToggle(isLoading)
    try {
      const { data } = await listrewards()
      if (data.success) {
        this.setState({
          Rewards: data.data,
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('There was some error. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  onDelete = async (rewardsId) => {
    let check = window.confirm(
      "Are you sure you want to delete this Rewards ?"
    );

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ rewardsId });

        messageShow(data.message);
        this.refresh();

        if (data.success) {
          
          this.refresh();
        }
      } catch (error) {
        messageShow(error);
      }
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  render() {
    const {Rewards , isLoading } = this.state;
    const list = Rewards
    return (
      <div>
                    <Grid style={{ paddingLeft: '1230px',paddingTop:"20px" }}>
                       <Link to={routes.createRewards.path}>
                      <Button
                          aria-label={"Save"}
                          size="medium"
                          color={"primary"}
                          variant="contained"
                      >
                          CREATE
                      </Button>
                        </Link>
                        </Grid>

                    <SectionPaper>
                  <Table padding={"normal"}>
                  <TableHead>
                  <TableRow>
                  <TableCell width={50}>Image</TableCell>
                  <TableCell width={100}>Earned Points</TableCell>
                  <TableCell width={100}>Earned values</TableCell>
                  <TableCell width={100}>Rewards name</TableCell> 
                  <TableCell width={150}>Offline Deals</TableCell>
                  <TableCell width={160}>Online Deals Percentage </TableCell> 
                  <TableCell width={110}>Min order amount</TableCell> 
                  <TableCell width={140}>Voucher Code</TableCell> 
                  <TableCell width={140}>Issue Volume</TableCell> 
                  <TableCell width={140}>Start date </TableCell> 
                  <TableCell width={140}>Expiry date </TableCell> 
                  <TableCell width={160} align="center">
                      Actions </TableCell> 
                  </TableRow>
                </TableHead>
                
                {isLoading ? (
                <Loading />
                ) : list.length === 0 ? (
                      <TableBody>
                      <TableRow>
                        <TableCell width={200}>No Rewards Found </TableCell>
                        {/* <EmptyMessage message={" No products found"} /> */}
                      </TableRow>
                      </TableBody> 
                ) : (
                <TableBody>
                
                {list.map(({_id,image,offlineDeals,onlineDeals,rewardName,earnedPoints,earnedValues,voucherCode,issueVolume,startDate,expiryDate }) => (

                
                <TableRow key={_id}>
                <TableCell>
                 <Image
                 src={getImageSource(`${image}`)}
                 defaultSrc={getImageSource()}
                 />             
                </TableCell>
                <TableCell align="center">{earnedPoints}</TableCell>
                <TableCell align="center">{earnedValues}</TableCell>
                <TableCell align="center" width={100}>{rewardName}</TableCell>
                <TableCell  align="center">
                <Checkbox
                checked={offlineDeals}
                // disabled
                color="primary"
                />
                </TableCell>
                <TableCell align="center">{onlineDeals.discountPercentage}</TableCell>
                <TableCell align="center">{onlineDeals.minOrderAmount}</TableCell>
                <TableCell align="center">{voucherCode}</TableCell>
                <TableCell align="center">{issueVolume}</TableCell>
                <TableCell>{ dateFormat(dateParseISO(startDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>{ dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd') }</TableCell>
                <TableCell>
                  <div style={{  width: 150 }}>
                  <Button href={`/rewards/edit/${_id}`}><EditIcon/></Button>
                  <Button  color="error" onClick={() => this.onDelete(_id)}>
                  <DeleteIcon />  
                  </Button>
                  {/* <Button style={{  width: 30 }}>
                  <NotificationsIcon/>
                  </Button> */}
                  </div> 
                  </TableCell>
                  </TableRow>
                ))}

                </TableBody>
                )} 

                </Table>
              
      </SectionPaper>
      </div>
    )
  }
}
Rewards.propTypes = {
  listrewards:PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function listState(state) {
  return {
    rewardsParent: state.rewardsParent,
  };
}

export default connect( listState,{
  listrewards,
  remove,
  messageShow
  })(withStyles(styles)(Rewards))

