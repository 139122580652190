// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from "../../../setup/config/env";
import params from "../../../setup/config/params";
import index from "../index";

// Pages routes
export default {
  ratingFeedback: {
    path: "/ratingfeedback",
    component: index,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key,
  },
};
