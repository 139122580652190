// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Anchor from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
// UI Imports
import Typography from '@material-ui/core/Typography'
import IconArrowBack from '@material-ui/icons/ArrowBack'
import dateAddDays from 'date-fns/addDays'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import prettifyJSON from 'prettify-json'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import params from '../../../setup/config/params'
import { priceFormat } from '../../../setup/helpers'
import routes from '../../../setup/routes'
import { messageShow } from '../../common/api/actions'
import Image from '../../common/Image'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { getImageSource } from '../../product/api/routes'
import { cancel, orderCalculation, orderCalculationAdmin, updateOrderDetails, updateOrderDetailsSubscription } from '../api/actions/mutation'
import { detail } from '../api/actions/query'
import styles from './styles'



// Component
class Detail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,

      open: false,
      edit: false,
      editId: '',
      cartRequest: {},
      quantity1: '',
      totQuantity: '',
      noOfDeliveries: '',
      type: '',
      dates: [],
      
      detail: {
        order: null,
        orderItemDelivery: [],
        orderItems: [],
        settingsData: null,
        taxAmount:"",
        currency:"",
        currencydata:"",
      },

    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = async () => {
    const { detail, match: { params: { orderId } }, messageShow } = this.props

    this.isLoadingToggle(true)

    try {
      const { data } = await detail({ orderId })
      if (data.success) {
        this.setState({
          detail: data.data,
          currencySymbol : data.data.currencydata.symbol,
          cartRequest: data.data.order.cartrequest,
          type: data.data.order.type,
          dates: data.data.order.cartrequest.deliveryDates
        })
      } else {
        messageShow(data.message)
      }
    } catch (error) {
      messageShow('Some error occurred. Please try again.')
    } finally {
      this.isLoadingToggle(false)
    }
  }

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  back = () => {
    const { history } = this.props

    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(routes.orderList.path(1))
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  }

  upcomingDeliveries = () => {
    const { detail } = this.state

    let deliveries = {}
    //let deliveries = this.state.noOfDeliveries

    const today = new Date()

    const week = [
      dateAddDays(today, 1),
      dateAddDays(today, 2),
      dateAddDays(today, 3),
      dateAddDays(today, 4),
      dateAddDays(today, 5),
      dateAddDays(today, 6),
      dateAddDays(today, 7),
    ]

    for (let day of week) {
      detail.orderItems.map(({ _id, productId, amount, quantity, frequency, frequencyDays }) => {
        const dayName = dateFormat(day, 'iiii').toLowerCase()

        if (frequency === params.order.frequency.daily.key || frequencyDays.indexOf(dayName) !== -1) {
          const key = dateFormat(day, params.date.format.date)

          if (!deliveries[key]) {
            deliveries[key] = {
              key,
              day: dateFormat(day, 'iiii'),
              items: []
            }
          }

          deliveries[key].items.push({
            product: productId,
            quantity,
          })
        }

        return null
      })
    }

    return Object.values(deliveries)
  }

  prepaidSubscriptionRefundAmount = () => {
    const { detail } = this.state

    let refundAmount = 0
    let perDeliveryAmount = detail.order.amountTotal / detail.orderItemDelivery.length

    for (let item of detail.orderItemDelivery) {
      if (item.date > detail.order.cancelledAt) {
        refundAmount += perDeliveryAmount
      }
    }
    return refundAmount
  }

  onType = ({ target: { value } }, index) => {
    const { detail: { orderItems } } = this.state
    //this.setState({ quantity1: value })

    orderItems[index].quantity = value > 0 ? parseInt(value) : 0

    this.setState({
      orderItems
    })
  }

  onTypeQuantity = ({ target: { value } }, index) => {
    const { detail: { orderItems, orderItemDelivery } } = this.state
    const { messageShow } = this.props

    if (value > orderItems[0].quantity) {
      //messageShow('Exceeds Maximum Quantity')
    } else {
      orderItemDelivery[index].quantity = value > 0 ? parseInt(value) : 0

      this.setState({
        orderItemDelivery
      })
    }
  }

  setFormat = async () => {
    const { match: { params: { order } } } = this.props
    const { detail: { orderItems }, cartRequest } = this.state
    const items = {}

    // const orderType = params.order.types.oneTime.key
    const orderType = this.state.type
    // const deliveryDates = this.state.dates
    // const deliveries = this.state.noOfDeliveries

    for (let i = 0; i < orderItems.length; i++) {
      const count = orderItems[i].quantity
      const productId = orderItems[i].productId._id
      items[productId] = { count, product: orderItems[i].productId }
    }

    const cartData = {
      orderType: orderType,
      items: items,
      countTotal: 0,
      amountTotal: 0,
      amountTotalWeekly: 0,
      deliveries: 0,
      // deliveries: deliveries,
      deliveryDates: []
    }

    // cartRequest.items[0]['count'] = '3'
    // const { cartData } = cartRequest
    return cartData
  }

  getCalculation = async () => {
    const { match: { params: { orderId } }, orderCalculation } = this.props
    const { detail: { orderItems } } = this.state

    const cartData = await this.setFormat()

    const { data } = await orderCalculation({ cart: cartData })

    return data;
  }

  getCalculationSubscribe = async (qtySum) => {
    const { match: { params: { orderId } }, orderCalculationAdmin } = this.props
    const { detail: { orderItems } } = this.state

    let totQuantity = qtySum
    const { data } = await orderCalculationAdmin({ orderId, totQuantity })
    return data;
  }

  submit = async () => {
    const { match: { params: { orderId } }, updateOrderDetails, messageShow } = this.props
    const { detail, detail: { orderItems }, editId } = this.state

    const productId = detail.orderItems[editId].productId._id
    // console.log("Quantity " + this.state.quantity1)

    // this.state.cartRequest.items[0].count = this.state.quantity1
    // console.log("cart " + JSON.stringify(this.state.cartRequest))

    try {
      const cal = await this.getCalculation()

      detail.order.amountTotal = cal.data.amountTotal
      detail.orderItems[editId].amount = cal.data.items[productId].product.totalAmount

      this.setState({ detail })

      const { data } = await updateOrderDetails({ orderId, orderItems, editId, detail })

      if (data.success) {
        this.setState({ edit: false })
        messageShow('Quantity has been updated successfully.')
      } else {
        messageShow(data.message)
      }
    } catch (e) {
      // TODO: Better error handle
      console.error(e)
    }
  }


  onUpdateSave = async () => {
    const { match: { params: { orderId } }, updateOrderDetailsSubscription, messageShow } = this.props
    const { detail, detail: { orderItems, orderItemDelivery } } = this.state

    //const productId = detail.orderItems[editId].productId._id

    let qtySum = 0
    for (let i = 0; i < orderItemDelivery.length; i++) {
      qtySum += orderItemDelivery[i].quantity
    }
    this.setState({ totQuantity: qtySum })

    try {
      const cal = await this.getCalculationSubscribe(qtySum)

      detail.order.amountTotal = cal.data.amountTotal
      // detail.orderItems[editId].amount = cal.data.items[productId].product.totalAmount

      this.setState({ detail })

      const { data } = await updateOrderDetailsSubscription({ orderId, orderItems, detail, orderItemDelivery })

      if (data.success) {
        this.setState({ edit: false })
        this.refresh()
        messageShow('Quantity has been updated successfully.')
      } else {
        messageShow(data.message)
      }
    } catch (e) {
      // TODO: Better error handle
      console.error(e)
    }
  }


  // Edit order
  handleEdit = (index) => {
    this.setState({ edit: true, editId: index })
  }

  onDone = (index) => {
    this.setState({ edit: false, editId: index })
  }


  render() {
    const { classes, auth } = this.props
    const { isLoading, detail, edit, editId ,currencySymbol} = this.state

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            Order Details
          </Typography>

          {
            detail.order && (detail.order.paymentId.type === params.payment.types.online.key || detail.order.paymentId.type === params.payment.types.wallet.key) &&
            <Button color="inherit" onClick={this.handleOpen}>Advance Payment Info</Button>
          }
        </Toolbar>

        {
          isLoading
            ? <Loading />
            : detail && detail.order && detail.order._id &&
            <div>
              {/* Order cancel status */}
              {
                detail.order.isCancelled &&
                <SectionPaper padding style={{ color: 'red', paddingBottom: 0 }}>
                  This order has been cancelled by the customer.
                  </SectionPaper>
              }

              {/* Order details */}
              <SectionPaper padding>
                <Grid container spacing={10}>
                  <Grid item sm>
                    <Typography variant="overline">Order ID:</Typography>
                    <Typography variant="subtitle2">
                      {detail.order.id.toUpperCase()}
                    </Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Date:</Typography>
                    <Typography variant="subtitle2">{dateFormat(dateParseISO(detail.order.createdAt), params.date.format.full)}</Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Type:</Typography>
                    <Typography variant="subtitle2">{params.order.types[detail.order.type].title}</Typography>
                  </Grid>

                  <Grid item sm ></Grid>
                </Grid>

                <Grid container spacing={10} style={{ marginTop: 10 }}>
                  <Grid item sm>
                    <Typography variant="overline">Customer:</Typography>
                    <Typography variant="subtitle2">
                      <Link to={routes.customerDetail.path(detail.order.userId._id)}>
                        <Anchor component="span" style={{ fontWeight: 500 }} color={'primary'}>
                          {detail.order.userId.name} ({detail.order.userId.email})
                          </Anchor>
                      </Link>
                    </Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Pincode:</Typography>
                    <Typography variant="subtitle2">{detail.order && detail.order.pincodeId && detail.order.pincodeId.pincode}</Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Address:</Typography>
                    <Typography variant="subtitle2">
                    {detail.order && detail.order.addressId && detail.order.addressId.street1} {detail.order && detail.order.addressId && detail.order.addressId.street2}
                    
                    </Typography>
                  </Grid>

                  <Grid item sm />
                </Grid>

                <Grid container spacing={10}>
                  <Grid item sm>
                    <Typography variant="overline">Total {detail.order.type === params.order.types.subscription.key && 'Weekly'} Amount:</Typography>
                    <Typography variant="subtitle2">{currencySymbol} {priceFormat(detail.order.cartrequest.grandTotal)}</Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Payment Type:</Typography>
                    <Typography variant="subtitle2">{detail.order.paymentId.type}</Typography>
                  </Grid>

                  <Grid item sm>
                    <Typography variant="overline">Paid:</Typography>
                    <Typography variant="subtitle2">{detail.order.paymentId.status === 'done' ? 'Done' : 'Pending'}</Typography>
                  </Grid>

                  <Grid item sm>
                    <>
                      <Typography variant="overline">Delivered:</Typography>
                      <Typography variant="subtitle2">{detail.order.isDelivered ? 'Yes' : 'No'}</Typography>
                    </>
                  </Grid>
                </Grid>

                {
                  detail.order.type === params.order.types.prepaidSubscription.key && detail.order.isCancelled &&
                  <Grid container spacing={10}>
                    <Grid item sm>
                      <Typography variant="overline">Refund Amount:</Typography>
                      <Typography variant="subtitle2">{`₹${priceFormat(this.prepaidSubscriptionRefundAmount())}`}</Typography>
                    </Grid>
                  </Grid>
                }
              </SectionPaper>

              {/* Order products */}
              <div>
                <Toolbar className={classes.toolbar} style={{ border: 'none' }}>
                  <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                    Order Items
                    </Typography>
                </Toolbar>

                <SectionPaper style={{ paddingTop: 0 }}>
                  <Table padding={"normal"}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={85}>Image</TableCell>
                        <TableCell>Product</TableCell>
                        {/* <TableCell>Amount</TableCell> */}
                        <TableCell>Quantity</TableCell>
                        {/* <TableCell>Total</TableCell> */}
                        <TableCell>Category</TableCell>
                        <TableCell>Vendor</TableCell>
                        {/* <TableCell>Actions</TableCell> */}
                        {
                          params.order.types.subscription.key === detail.order.type &&
                          <TableCell>Frequency</TableCell>
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        detail.orderItems.map(({ _id, productId, amount, quantity, frequency, frequencyDays }, index) =>
                          <TableRow key={_id}>
                            <TableCell>
                              <Link to={routes.productEdit.path(productId._id || "")}>
                                <Image
                                  src={productId.image}
                                  defaultSrc={getImageSource()}
                                />
                              </Link>
                            </TableCell>
                            <TableCell>{productId.name}</TableCell>
                            {/* <TableCell>{`$${amount}`}</TableCell> */}
                            {edit && editId === index ?
                              <TableCell>
                                {/* Input - Discounted price */}
                                <Grid item xs={8}>
                                  <Input
                                    type={'number'}
                                    name={'quantity'}
                                    value={quantity}
                                    inputProps={{ min: "0", step: "1", max: quantity }}
                                    onChange={(event) => this.onType(event, index)}
                                    margin={'dense'}
                                    autoComplete={'off'}
                                  />
                                </Grid>
                              </TableCell>
                              : <TableCell>{quantity}</TableCell>
                            }
                            {/* <TableCell>{`$${priceFormat(quantity * amount)}`}</TableCell> */}
                            <TableCell>{productId.categoryId.name}</TableCell>
                            <TableCell>{productId.vendorId.name}</TableCell>
                            {
                              params.order.types.subscription.key === detail.order.type &&
                              <TableCell>
                                <p style={{ textTransform: 'capitalize' }}>{frequency}</p>
                                {
                                  frequency === params.order.frequency.days.key &&
                                  <p style={{ textTransform: 'capitalize' }}>{frequencyDays.join(', ')}</p>
                                }
                              </TableCell>
                            }
                            {/* <TableCell>
                              {
                                !detail.order.isCancelled ?
                                  edit && editId === index ?
                                    params.order.types.prepaidSubscription.key === detail.order.type ? <Button disabled color="inherit" onClick={() => this.submit(index)}>Done</Button>
                                      : <Button color="inherit" onClick={() => this.submit(index)}>Done</Button>
                                    : params.order.types.prepaidSubscription.key === detail.order.type ? <Button disabled color="inherit" onClick={() => this.handleEdit(index)}>Edit</Button>
                                      : <Button color="inherit" onClick={() => this.handleEdit(index)}>Edit</Button>
                                  : <Button color="inherit" disabled >Edit</Button>
                              }
                            </TableCell> */}
                          </TableRow>
                          // ): null )
                        )
                      }
                    </TableBody>
                  </Table>
                </SectionPaper>
              </div>

              {/* Deliveries */}
              {
                detail.order.type === params.order.types.subscription.key && !detail.order.isCancelled &&
                <div>
                  <Toolbar className={classes.toolbar} style={{ border: 'none' }}>
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                      Upcoming Deliveries
                      </Typography>
                  </Toolbar>

                  <SectionPaper style={{ paddingTop: 0 }}>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Day</TableCell>
                          {/* <TableCell>Quantity</TableCell>
                          <TableCell>Amount</TableCell> */}
                          <TableCell>Items</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          this.upcomingDeliveries() && this.upcomingDeliveries().length > 0 &&
                          this.upcomingDeliveries().map(({ key, day, items }, i) =>
                            <TableRow key={key}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{key}</TableCell>
                              <TableCell>{day}</TableCell>
                              <TableCell>
                                <List dense>
                                  {
                                    items.length > 0 &&
                                    items.map(item =>
                                      <ListItem key={item.product._id} style={{ paddingLeft: 0 }}>
                                        <ListItemText
                                          primary={`${item.product.name} - ${item.product.unit}`}
                                          secondary={`Quantity: ${item.quantity}`}
                                        />
                                      </ListItem>
                                    )
                                  }
                                </List>
                              </TableCell>
                            </TableRow>
                          )
                        }

                      </TableBody>
                    </Table>
                  </SectionPaper>
                </div>
              }

              {
                detail.order.type === params.order.types.prepaidSubscription.key &&
                <div>
                  <Toolbar className={classes.toolbar} style={{ border: 'none' }}>
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                      Deliveries
                      </Typography>
                    {
                      edit ? <Button disabled color="inherit" onClick={this.onUpdateSave} >Save</Button>
                        : <Button color="inherit" onClick={this.onUpdateSave} >Save</Button>
                    }

                  </Toolbar>

                  <SectionPaper style={{ paddingTop: 0 }}>
                    <Table padding={"normal"}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={100}>#</TableCell>
                          <TableCell width={100}>Day</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          detail.orderItemDelivery && detail.orderItemDelivery.map(({ _id, date, quantity, amount }, i) =>
                            <TableRow key={_id}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{dateFormat(dateParseISO(date), 'EEEE')}</TableCell>
                              <TableCell>{dateFormat(dateParseISO(date), params.date.format.dateText)}</TableCell>
                              {edit && editId === i ?
                                <TableCell>
                                  {/* Input - Discounted price */}
                                  <Grid item xs={4}>
                                    <Input
                                      type={'number'}
                                      name={'quantity'}
                                      value={quantity}
                                      inputProps={{ min: "0", step: "1" }}
                                      onChange={(event) => this.onTypeQuantity(event, i)}
                                      margin={'dense'}
                                      autoComplete={'off'}
                                    />
                                  </Grid>
                                </TableCell>
                                : <TableCell>{quantity}</TableCell>
                              }
                              <TableCell>{amount}</TableCell>
                              <TableCell>
                                {
                                  !detail.order.isCancelled ?
                                    edit && editId === i ?
                                      <Button color="inherit" onClick={() => this.onDone(i)}>Done</Button>
                                      : <Button color="inherit" onClick={() => this.handleEdit(i)}>Edit</Button>
                                    : <Button color="inherit" disabled >Edit</Button>
                                }
                              </TableCell>
                            </TableRow>
                          )
                        }
                        {/* <TableRow>
                          <TableCell align={'center'}>
                            {
                              <Button color="inherit" >Save</Button>
                            }

                          </TableCell>
                        </TableRow> */}
                      </TableBody>
                    </Table>
                  </SectionPaper>
                </div>
              }

              {/* Modal - Advance Payment Info */}
              <Dialog onClose={this.handleClose} open={this.state.open} aria-labelledby="simple-dialog-title" fullWidth={true}>
                <DialogTitle id="simple-dialog-title">Advance Payment Info</DialogTitle>
                <DialogContent>
                  {
                    detail.order.paymentId && detail.order.paymentId.gatewayResponse &&
                    <div>
                      <p>Payment</p>
                      <pre style={{ maxWidth: 500, whiteSpace: 'pre-wrap', overflowWrap: 'keep-all' }}>{prettifyJSON(detail.order.paymentId.gatewayResponse)}</pre>
                    </div>
                  }

                  {
                    detail.order.subscription &&
                    <div>
                      <p>
                        Subscription <Button component={'a'} href={`${params.links.payment.razorpay.subscription}${detail.order.subscription.id}`} target='_blank'>MORE DETAILS</Button>
                      </p>
                      <pre style={{ maxWidth: 500, whiteSpace: 'pre-wrap', overflowWrap: 'keep-all' }}>{prettifyJSON(detail.order.subscription)}</pre>
                    </div>
                  }

                  {
                    detail.order.plan &&
                    <div>
                      <p>
                        Plan <Button component={'a'} href={`${params.links.payment.razorpay.plan}${detail.order.plan.id}`} target='_blank'>MORE DETAILS</Button>
                      </p>
                      <pre style={{ maxWidth: 500, whiteSpace: 'pre-wrap', overflowWrap: 'keep-all' }}>{prettifyJSON(detail.order.plan)}</pre>
                    </div>
                  }
                </DialogContent>
              </Dialog>
            </div>
        }
      </div>
    )
  }
}

// Component Properties
Detail.propTypes = {
  auth: PropTypes.object.isRequired,
  detail: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orderCalculation: PropTypes.func.isRequired,
  orderCalculationAdmin: PropTypes.func.isRequired,
  updateOrderDetails: PropTypes.func.isRequired,
  updateOrderDetailsSubscription: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

// Component State
function detailState(state) {
  return {
    auth: state.auth,
  }
}

export default connect(detailState, { detail, messageShow, orderCalculation, orderCalculationAdmin, updateOrderDetails, updateOrderDetailsSubscription, cancel })(withStyles(styles)(Detail))
