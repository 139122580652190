import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Actions

// Create 
export function listrewards() {
    return dispatch => {
    return axios.get(API_URL+"/api/rewards")
}
}

export function getrewards({id}) {
    return dispatch => {
    return axios.get(`${API_URL}/api/rewards/${id}`)
}
}
