// App Imports
import { API_URL, IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import Save from '../Save'

// Image
export function imageSettings(image = '/default.jpg') {
  return `${ API_URL }/${ params.settings.image.path }/${ image }`
}

export function getImageSource(image = '/default.jpg') {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Pages routes
export default {
  settings: {
    path: (vendorId = ':vendorId') => (`/settings/${vendorId}`),
    component: Save,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
}
