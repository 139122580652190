// Imports
import { withStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// UI Imports
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import dateFormat from "date-fns/format";
import dateParseISO from "date-fns/parseISO";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// App Imports
import params from "../../../setup/config/params";
import { messageShow } from "../../common/api/actions";
import EmptyMessage from "../../common/EmptyMessage";
import Image from "../../common/Image";
import Loading from "../../common/Loading";
import SectionPaper from "../../common/SectionPaper";
import { publishToggle, remove } from "../api/actions/mutation";
import { listParent } from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import styles from "./styles";

// Component
class List extends PureComponent {
  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { listParent } = this.props;

    listParent();
  };

  onDelete = async (categoryId) => {
    let check = window.confirm(
      "Are you sure you want to delete this category?"
    );

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ categoryId });

        messageShow(data.message);

        if (data.success) {
          this.refresh();
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onPublishToggle = async (event, categoryId) => {
    const isPublished = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${
        isPublished ? "publish" : "unpublish"
      } this category?`
    );

    if (check) {
      const { publishToggle, messageShow } = this.props;

      try {
        const { data } = await publishToggle({ categoryId, isPublished });

        messageShow(data.message);

        if (data.success) {
          this.refresh();
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  render() {
    const {
      categoriesParent: { isLoading, list },
      classes,
    } = this.props;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Categories
          </Typography>

          <Link to={routes.categoryCreate.path}>
            <Button color="inherit">Create</Button>
          </Link>
        </Toolbar>

        <SectionPaper>
          {isLoading ? (
            <Loading />
          ) : list.length === 0 ? (
            <EmptyMessage message={"You have not added any categories yet."} />
          ) : (
            <Table padding={"normal"}>
              <TableHead>
                <TableRow>
                  <TableCell width={85}>Image</TableCell>
                  <TableCell width={200}>Name</TableCell>
                  <TableCell width={200} align="center">
                    Subcategories
                  </TableCell>
                  <TableCell width={180}>Updated</TableCell>
                  <TableCell width={80} align="center">
                    Published
                  </TableCell>
                  <TableCell width={180} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {list.map(({ _id, image, name, isPublished, updatedAt }) => (
                  <TableRow key={_id}>
                    <TableCell>
                      <Image
                        src={getImageSource(`${image}`)}
                        defaultSrc={getImageSource()}
                      />
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell align="center">
                      <Button
                        component={Link}
                        variant="contained"
                        color="secondary"
                        to={routes.categoryManage.path(_id)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                    <TableCell>
                      {dateFormat(
                        dateParseISO(updatedAt),
                        params.date.format.full
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={isPublished}
                        onChange={(event) => this.onPublishToggle(event, _id)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="secondary"
                        component={Link}
                        to={routes.categoryEdit.path(_id)}
                      >
                        Edit
                      </Button>
                      <Button color="error" onClick={() => this.onDelete(_id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </SectionPaper>
      </div>
    );
  }
}

// Component Properties
List.propTypes = {
  categoriesParent: PropTypes.object.isRequired,
  listParent: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function listState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect(listState, {
  listParent,
  remove,
  publishToggle,
  messageShow,
})(withStyles(styles)(List));
