// Imports
import axios from 'axios'
// App Imports
import { API_URL } from '../../../../setup/config/env'


// Actions

// Create or update
export function createOrUpdate({ product }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productCreateOrUpdate',
      params: product
    })
  }
}

// Create or update
export function productPurchase({ purchaseCount, productId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productPurchase',
      params: {purchaseCount: purchaseCount, productId: productId}
    })
  }
}

// Feature toggle
export function featureToggle({ productId, isFeatured }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productFeatureToggle',
      params: { productId, isFeatured }
    })
  }
}

// Publish toggle
export function publishToggle({ productId, isPublished }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productPublishToggle',
      params: { productId, isPublished }
    })
  }
}

// Delete
export function remove({ productId }) {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'productDelete',
      params: { productId  }
    })
  }
}

// onResetStocks
export function onResetStocks() {
  return dispatch => {
    return axios.post(API_URL, {
      operation: 'onResetStocks',
      params: { }
    })
  }
}

//LiveCounterItem Toggle 
// export function liveCounterItemToggle({ productId, isLiveCounterItem }) {
//   return dispatch => {
//     return axios.post(API_URL, {
//       operation: 'productLiveCounterItemToggle',
//       params: { productId, isLiveCounterItem }
//     })
//   }
// }
export function updateLiveCounterItems({ productId,isLiveCounterItem}) {
  return dispatch => {
      return axios.post(`${API_URL}/api/product/updateLiveCounterItems/${ productId }`,{isLiveCounterItem})
    }
}