const styles = (theme) => ({
  toolbar: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  },
  grow: {
    flexGrow: 1,
  },
});

export default styles;
