// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'


export function getPoints() {
    return dispatch => {
    return axios.get(API_URL+"/api/points")
}
}