import uberDelivery from "../index";
import params from "../../../setup/config/params";
export default {
  uberDelivery: {
    path: (orderId = ":orderId") => `/uberDelivery/${orderId}`,
    component: uberDelivery,
    auth: true,
    role: [params.user.roles.admin.key, params.user.roles.vendor.key],
  },
};
