// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'


export function updatePoints({_id, points, value}) {
  return dispatch => {
    return axios.post(`${API_URL}/api/points/update/${_id}`, {
      points, value
    })
  }
}
