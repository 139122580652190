import axios from 'axios';
import { API_URL } from '../../../../setup/config/env';

export const createEvent = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/api/marketing`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        const { imagePath, ...otherData } = response.data;
        
        return { imagePath, ...otherData };
    } catch (error) {
        if (error.response && error.response.data) {
            return { error: error.response?.data?.error || "An unknown error occurred" };
        }
        console.log(error);
        return { error: "An unknown error occurred. Please try again!" };
    }
};

export const sendEmail = async (id, customerType) => {
    try {
        const response = await axios.post(`${API_URL}/api/marketing/email/${id}/send`, {
            customerType: customerType
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const cancelSend = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/api/marketing/email/${id}/cancel`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deleteEvent = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/email/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updateEmail = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/marketing/email/${id}`,formData,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error updating email:", error);
        return { error: error.response?.data?.message || "An error occurred while updating the email" };
    }
};

export const sendNotification = async (id, customerType) => {
    try {
        const response = await axios.post(`${API_URL}/api/marketing/push-notification/${id}/send`, {
            customerType: customerType
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deleteNotification = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/push-notification/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updateNotification = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/marketing/push-notification/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    } catch (error) {
        console.error('Error in updateNotification:', error);
        return { error: error.response?.data?.error || "An error occurred while updating the notification" };
    }
};

export const sendFbPost = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/api/marketing/fb-post/${id}/send`);
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || "An unknown error occurred" };
    }
};

export const deletePost = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/fb-post/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const updatePost = async (id, formData) => {
    try {
        const response = await axios.put(`${API_URL}/api/marketing/fb-post/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        return { error: error.response?.data?.error || error };
    }
};


export const deleteSpamByEmail = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/spam/${email}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteBouncedByEmail = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/bounced/${email}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteAllSpam = async () => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/spam/all`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};

export const deleteAllBounced = async () => {
    try {
        const response = await axios.delete(`${API_URL}/api/marketing/bounced/all`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Network Error, Please try again');
    }
};
