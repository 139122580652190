// src/components/DeliveryPage.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Toolbar, Typography, Paper, Button, Link } from "@material-ui/core";
import {
  getOrderDetails,
  createQuote,
  createDelivery,
  vendorDetails,
  getUberDeliveryDetails,
  getDeliveryDetails,
} from "./api/actions/query";
import styles from "./styles";

class DeliveryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: null,
      order: null,
      quote: null,
      delivery: null,
      uberDelivery: null, // New state to store Uber delivery details
      stage: "Quote",
      error: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchVendorDetails();
    this.fetchOrderDetails();
    this.fetchUberDeliveryDetails();
  }

  fetchOrderDetails = async () => {
    const { match } = this.props;
    const { orderId } = match.params;

    try {
      const { data } = await getOrderDetails(orderId);
      const { order } = data;
      this.setState({ order, isLoading: false });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  fetchVendorDetails = async () => {
    try {
      const { data } = await vendorDetails();
      this.setState({ vendor: data.data, isLoading: false });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  fetchUberDeliveryDetails = async () => {
    const { match } = this.props;
    const { orderId } = match.params;

    try {
      const { data } = await getUberDeliveryDetails(orderId);
      const { uberDelivery } = data.data;
      if (uberDelivery) {
        this.setState({ uberDelivery, stage: "Done", isLoading: false });
      }
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  createQuote = async () => {
    const { order, vendor } = this.state;

    if (!order || !vendor) {
      this.setState({
        error: "Order or vendor details not available",
        isLoading: false,
      });
      return;
    }

    const pickupAddress = {
      street_address: [vendor.address.street1, vendor.address.street2]
        .join(" ")
        .trim(),
      city: vendor.address.city,
      state: vendor.address.state,
      zip_code: vendor.address.pincode,
      country: vendor.address.country,
    };

    const dropoffAddress = {
      street_address: [order.addressId.street1, order.addressId.street2]
        .join(" ")
        .trim(),
      city: order.addressId.city,
      state: order.addressId.state || null,
      zip_code: order.addressId.pincode,
      country: order.addressId.country,
    };

    const quoteData = {
      pickupAddress,
      dropoffAddress,
      pickupPhoneNumber: vendor.phoneNumber,
      dropoffPhoneNumber: order.userId.mobile,
      manifestTotalValue: Math.round(order.amountTotal * 100),
    };

    try {
      const { data } = await createQuote(quoteData);

      this.setState({ quote: data.data, isLoading: false, stage: "Delivery" });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  createDelivery = async () => {
    const { order, vendor, quote } = this.state;

    if (!order || !vendor || !quote) {
      this.setState({
        error: "details not available",
        isLoading: false,
      });
      return;
    }

    const { id: quoteId } = quote;

    const items = Object.values(order.cartrequest.items).map((item) => ({
      name: item.product.name,
      quantity: item.count,
      weight: item.product.weight || 1,
      value: item.product.price,
      description: item.product.description,
    }));

    const deliveryData = {
      orderId: order._id,
      quoteId,
      pickupDetails: {
        name: vendor.name,
        address: {
          street_address: [vendor.address.street1, vendor.address.street2]
            .join(" ")
            .trim(),
          city: vendor.address.city,
          state: vendor.address.state,
          zip_code: vendor.address.pincode,
          country: vendor.address.country,
        },
        phoneNumber: vendor.phoneNumber,
      },
      dropoffDetails: {
        name: order.userId.name,
        address: {
          street_address: [order.addressId.street1, order.addressId.street2]
            .join(" ")
            .trim(),
          city: order.addressId.city,
          state: order.addressId.state || null,
          zip_code: order.addressId.pincode,
          country: order.addressId.country,
        },
        phoneNumber: order.userId.mobile,
      },
      manifestItems: items,
      manifestTotalValue: Math.round(order.amountTotal * 100),
      externalStoreId: "123",
    };
    try {
      const { data } = await createDelivery(deliveryData);

      this.setState({ delivery: data.data, isLoading: false, stage: "Done" });
      this.fetchUberDeliveryDetails();
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  handleButtonClick = () => {
    const { stage } = this.state;

    if (stage === "Quote") {
      this.createQuote();
    } else if (stage === "Delivery") {
      this.createDelivery();
    }
  };

  getDeliveryDetails = async () => {
    const { uberDelivery } = this.state;

    const { deliveryId } = uberDelivery;

    try {
      const { data } = await getDeliveryDetails(deliveryId);
      this.setState({
        uberDelivery: {
          ...this.state.uberDelivery,
          deliveryStatus: data.data.status,
        },
        isLoading: false,
      });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { order, quote, delivery, uberDelivery, error, isLoading, stage } =
      this.state;

    if (isLoading) {
      return <Typography>Loading...</Typography>;
    }

    if (error) {
      return <Typography>Error: {error}</Typography>;
    }

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Delivery Details
          </Typography>
        </Toolbar>
        <div style={{ padding: "18px" }}>
          {order && (
            <Paper style={{ padding: "16px", marginBottom: "16px" }}>
              <Typography variant="h6">Order Details</Typography>
              <Typography variant="body1">Order ID: {order._id}</Typography>
              <Typography variant="body1">
                Customer: {order.userId.name}
              </Typography>
              <Typography variant="body1">
                Address: {order.addressId.street1} {order.addressId.street2},{" "}
                {order.addressId.city},{order.addressId.country},{" "}
                {order.addressId.pincode}
              </Typography>
            </Paper>
          )}
          {quote && (
            <Paper style={{ padding: "16px", marginBottom: "16px" }}>
              <Typography variant="h6">Quote Details</Typography>
              <Typography variant="body1">Quote ID: {quote.id}</Typography>
              <Typography variant="body1">Price: {quote.fee}</Typography>
            </Paper>
          )}
          {delivery && (
            <Paper style={{ padding: "16px", marginBottom: "16px" }}>
              <Typography variant="h6">Delivery Details</Typography>
              <Typography variant="body1">
                Delivery ID: {delivery.id}
              </Typography>
              <Typography variant="body1">Status: {delivery.status}</Typography>
            </Paper>
          )}
          {uberDelivery && (
            <Paper style={{ padding: "16px", marginBottom: "16px" }}>
              <Typography variant="h6">Uber Delivery Details</Typography>
              <Typography variant="body1">
                Delivery Fee: {uberDelivery.fee}
              </Typography>
              <Typography variant="body1">
                Tracking URL:{" "}
                <Link
                  href={uberDelivery.trackingUrl}
                  target="_blank"
                  style={{ fontSize: "14px" }}
                >
                  {uberDelivery.trackingUrl}
                </Link>
              </Typography>
              <Typography variant="body1">
                Status: {uberDelivery.deliveryStatus}
              </Typography>
            </Paper>
          )}
          {stage !== "Done" && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleButtonClick}
            >
              Create {stage}
            </Button>
          )}
          {stage === "Done" && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.getDeliveryDetails}
            >
              Get Uber Delivery Details
            </Button>
          )}
        </div>
      </div>
    );
  }
}

DeliveryPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryPage);
