// Imports
import axios from 'axios'

// App Imports
import { API_URL } from '../../../../setup/config/env'

// Get All Currencies
export function getAllFaqs() {
  return dispatch => {
    return axios.get(`${API_URL}/api/faq`)
  }
}
