// Imports
import Button from '@mui/material/Button'
import React, { PureComponent , useState }  from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { messageShow,upload } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import EmptyMessage from "../../common/EmptyMessage";
import { getImageSource } from "../apiRewards/routes";
import ImageComp from '../../common/Image';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import routes from '../api/routes'
import styles from './styles'
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@mui/material/MenuItem';
import { getrewards } from '../apiRewards/actions/query'
import { updaterewards } from '../apiRewards/actions/mutation'
// UI Imports
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import FileUpload from "@mui/icons-material/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getPoints } from "../../rewards/apiPoints/actions/query";

// Component
class updateRewards extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      dynamicPoints:'',
      dynamicValues:'',
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      rewards: { 
        rewardName: "",
        earnedPoints: "",
        earnedValues: "",
        offlineDeals: false,
        onlineDeals: {
          discountPercentage: "",
          minOrderAmount: "",
          maxDiscountAmount:""
        },
        voucherCode: "",
        issueVolume: "",
        startDate: dayjs(),
        expiryDate: dayjs(),
        startTime: dayjs(),
        endTime: dayjs(),
        image: "default.jpg",
      },
    };
  }


  componentDidMount() {
    this.refresh();
    this.refreshPoints();

  }
  refreshPoints = async () => {
    const { getPoints, messageShow } = this.props;
  
    this.isLoadingToggle(true);
  
    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          dynamicPoints: data.data[0].points,
          dynamicValues:data.data[0].value
        });
      } 
    } catch (error) {
      // console.log( error);
      messageShow("Some error occurred. Please try again.",error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  refresh = async () => {
    const { getrewards, isLoading, match: { params: { rewardsId } } } = this.props;
    this.isLoadingToggle(isLoading);
  
    try {
      const { data } = await getrewards({ "id": rewardsId });
      if (data.success) {
        this.setState({
          rewards: data.data,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again. 55555',error);
    } finally {
      this.isLoadingToggle(false);
    }
  };
  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };
  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  handleDateChange = (key) => (date) => {
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [key]: date,
      },
    }));
  };

  handleTimeChange = (time, field) => {
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [field]: time,
      }
    }));
  };

  handleEarnedPointsChange = (event) => {
    const points = parseFloat(event.target.value);
    if (!isNaN(points)) {
      const { dynamicPoints, dynamicValues } = this.state;
      const calculatedValue = (points * dynamicValues) / dynamicPoints;
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: points,
          earnedValues: calculatedValue.toFixed(2),
        }
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: '',
          earnedValues: '',
        }
      });
    }
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "rewards");
    file.append("name", this.state.rewards.rewardName);
    file.append("file", event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { rewards } = this.state;

        setTimeout(() => {
          rewards.image = data.file;
          this.setState({
            rewards,
          });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.!");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
    } else {
      history.goBack();  
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  onUpdate = async (event) => {
    event.preventDefault();

    const {  updaterewards , messageShow,history} = this.props;

    const { rewards } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await updaterewards(rewards);

      this.isLoadingSubmitToggle(false);
      messageShow(data.message);
      
      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again in update ",error);
    }
  };

  onTypeName = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [name]: value
      }
    }));
  }

  onType = (event) => {
    const { name, value } = event.target;
  
    // Allow only digits
    const isNumeric = /^\d*$/.test(value);
  
    if (!isNumeric) {
      return;
    }
  
    const [parent, child] = name.split('.');
  
    if (child) {
      this.setState(prevState => ({
        rewards: {
          ...prevState.rewards,
          [parent]: {
            ...prevState.rewards[parent],
            [child]: value
          }
        }
      }));
    } else {
      this.setState(prevState => ({
        rewards: {
          ...prevState.rewards,
          [name]: value
        }
      }));
    }
  };
  onChangeOfflineDeals = (event) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        offlineDeals: event.target.checked,
      },
    });
  };

render() {
  const { classes } = this.props;
  const { rewards, isLoadingSubmit } = this.state;

 

  return (
    <SectionPaper>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={this.back}
        >
          <IconArrowBack />
        </IconButton>

        <Typography variant="h6" color="inherit">
          Update Rewards
        </Typography>
      </Toolbar>
      <Toolbar className={classes.toolbar}>
        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            <form onSubmit={this.onUpdate}>
              <Grid item xs={12} style={{ marginBottom: 24 }}>
                <TextField
                  name={"rewardName"}
                  value={nullToEmptyString(rewards.rewardName)}
                  variant="standard"
                  onChange={this.onTypeName}
                  label={"Reward Name"}
                  placeholder={"Enter reward name"}
                  required={true}
                  margin={"dense"}
                  autoComplete={"off"}
                  fullWidth
                />
              </Grid>
              <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedPoints"
                      value={rewards.earnedPoints}
                      variant="standard"
                      onChange={this.handleEarnedPointsChange}
                      label={"Earned Points"}
                      placeholder={"Enter Earned Points"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedValues"
                      value={rewards.earnedValues}
                      variant="standard"
                      label="Event Earned Values"
                      placeholder="Enter Earned Values"
                      required={true}
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.discountPercentage"}
                    value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.discountPercentage)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Discount Percentage"}
                    placeholder={"Enter Discount Percentage"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"voucherCode"}
                    value={nullToEmptyString(rewards.voucherCode)}
                    variant="standard"
                    onChange={this.onTypeName}
                    label={"Voucher Code"}
                    placeholder={"Enter Voucher Code"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.minOrderAmount"}
                    value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.minOrderAmount)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Min Order Amount"}
                    placeholder={"Enter Min Order Amount"}
                    // required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"onlineDeals.maxDiscountAmount"}
                    value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.maxDiscountAmount)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Max Discount Amount"}
                    placeholder={"Enter Max Discount Amount"}
                    // required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                
              </Grid>
              <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"issueVolume"}
                    value={nullToEmptyString(rewards.issueVolume)}
                    variant="standard"
                    onChange={this.onType}
                    label={"Issue Volume"}
                    placeholder={"Enter Issue Volume"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 24 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rewards.offlineDeals}
                        onChange={this.onChangeOfflineDeals}
                        name="offlineDeals"
                        color="primary"
                        margin="normal"
                        autoComplete="off"
                        fullWidth
                      />
                    }
                    label="Offline Deal"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={3} style={{ marginBottom: 24 }}>
              <Grid item xs={6} >
                <DatePicker
                    required
                    label="Start Date"
                    value={dayjs(rewards.startDate)}
                    slotProps={{textField:{variant:"standard" }}}
                    onChange={this.handleDateChange('startDate')}
                  />
                </Grid>
                <Grid item xs={6} >
                  <DatePicker
                    required
                    value={dayjs(rewards.expiryDate)}
                    label="Expiry Date"
                    slotProps={{ textField: { variant: "standard" } }}
                    onChange={this.handleDateChange('expiryDate')}
                  />
                </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                <Grid item xs={6}>
                <TimePicker
                  required
                  value={dayjs(rewards.startTime)}
                  label="Start Time"
                  inputVariant="standard"
                  onChange={(time) => this.handleTimeChange(time, 'startTime')}
                />
                </Grid>
                <Grid item xs={6}>
                <TimePicker
                  required
                  value={dayjs(rewards.endTime)}
                  label="End Time"
                  inputVariant="standard"
                  onChange={(time) => this.handleTimeChange(time, 'endTime')}
                />
                </Grid>
                </Grid>
              <Grid item xs={12} container spacing={3} style={{ marginBottom: 24 }}>
                <Grid
                  container
                  spacing={8}
                  className={classes.buttonUpload}
                  alignItems="center"
                >
                  <Grid item md={6}>
                    {!this.state.previewImage ? (
                      <a
                        href={getImageSource(rewards.image)}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <ImageComp
                          src={getImageSource(`${rewards.image}`)}
                          defaultSrc={getImageSource()}
                          size={"100%"}
                        />
                      </a>
                    ) : (
                      <img
                        height="auto"
                        width="100%"
                        src={this.state.previewImage}
                        alt="preview_rewards"
                      ></img>
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <input
                      accept={"image/png,image/jpeg"}
                      style={{ display: "none" }}
                      id={"contained-button-file"}
                      type={"file"}
                      onChange={this.onUpload}
                    />
                    <label htmlFor={"contained-button-file"}>
                      <Button
                        variant={"outlined"}
                        component={"span"}
                        type={"file"}
                        fullWidth
                      >
                        <FileUpload
                          fontSize="16"
                          style={{ marginInlineEnd: 16 }}
                        />
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button
                  type={"button"}
                  aria-label={"Close"}
                  variant="outlined"
                  color="secondary"
                  onClick={this.back}
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginInlineStart: 12 }}
                  type={"submit"}
                  aria-label={"Save"}
                  color={"primary"}
                  variant="contained"
                  disabled={isLoadingSubmit}
                >
                  upadate
                </Button>
              </Grid>
            </form>
          </SectionPaper>
        </Grid>
      </Toolbar>
    </SectionPaper>
  );
}
}
updateRewards.propTypes = {
  updaterewards:PropTypes.func.isRequired,
  getrewards:PropTypes.func.isRequired,
  getPoints:PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
function listState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect( listState,{
  getrewards,
  getPoints,
  updaterewards,
  upload,
  messageShow
  })(withStyles(styles)(updateRewards))

