import axios from "axios";
import { API_URL } from "../../../../setup/config/env";

export const fetchForecastData = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/api/analytics/${id}`);
        return response;
    } catch (error) {
        return { error: error.response?.data?.error || "Network Error" };
    }
}