import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllRatingFeedbacks, detail } from "./api/actions/query";
import { withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import styles from "./styles";
import { feedbackReply } from "./api/actions/mutation";

class RatingFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: [],
      open: false,
      selectedFeedback: null,
      message: "",
      sendOption: "notification",
      filter: "all",
    };
  }

  componentDidMount() {
    this.fetchFeedbacks();
  }

  fetchFeedbacks = async () => {
    const { getAllRatingFeedbacks } = this.props;
    try {
      const { data } = await getAllRatingFeedbacks();
      if (data) {
        const sortedFeedbacks = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        this.setState({ feedbacks: sortedFeedbacks });
      }
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  };

  handleClickOpen = (feedback) => {
    this.setState({
      open: true,
      selectedFeedback: feedback,
      sendOption: feedback.email ? "email" : "notification",
    });
  };

  handleClose = () => {
    this.setState({ open: false, message: "", sendOption: "notification" });
  };

  handleSend = async () => {
    const { selectedFeedback, message, sendOption } = this.state;
    const payload = {
      feedbackId: selectedFeedback._id,
      message,
      sendOption,
    };

    if (selectedFeedback.userId) {
      payload.userId = selectedFeedback.userId._id;
    }

    if (sendOption === "email") {
      payload.email = selectedFeedback.email || null;
    }

    try {
      const response = await feedbackReply(payload);
      if (response.success) {
        alert("Reply sent successfully.");
        this.fetchFeedbacks();
      } else {
        alert(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      alert("An error occurred while sending the reply.");
    }

    this.handleClose();
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleSendOptionChange = (event) => {
    this.setState({ sendOption: event.target.value });
  };

  handleFilterChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  filterFeedbacks = () => {
    const { feedbacks, filter } = this.state;
    if (filter === "replied") {
      return feedbacks.filter((feedback) => feedback.reply === true);
    } else if (filter === "notReplied") {
      return feedbacks.filter((feedback) => feedback.reply === false);
    } else {
      return feedbacks;
    }
  };

  render() {
    const { classes } = this.props;
    const { open, message, sendOption, selectedFeedback, filter } = this.state;
    const filteredFeedbacks = this.filterFeedbacks();

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Rating Feedback
          </Typography>
          <FormControl>
            <Select
              value={filter}
              onChange={this.handleFilterChange}
              displayEmpty
              style={{ padding: "5px 5px" }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="replied">Replied</MenuItem>
              <MenuItem value="notReplied">Not Replied</MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
        <div style={{ padding: "18px" }}>
          {filteredFeedbacks.length === 0 ? (
            <Typography variant="body1">No feedbacks to display</Typography>
          ) : (
            <Grid container spacing={3}>
              {filteredFeedbacks.map((feedback) => (
                <Grid item xs={12} key={feedback._id}>
                  <Paper style={{ padding: "16px", marginBottom: "16px" }}>
                    <Typography variant="body1">
                      User:{" "}
                      {feedback.userId ? feedback.userId.name : "Unknown User"}
                    </Typography>
                    {feedback.email && (
                      <Typography variant="body1">
                        Email: {feedback.email}
                      </Typography>
                    )}
                    <Typography variant="body1">
                      Rating: {feedback.rating}
                    </Typography>
                    <Typography variant="body1">
                      Feedback: {feedback.feedback}
                    </Typography>
                    <Typography variant="body1">
                      Time Sent: {new Date(feedback.createdAt).toLocaleString()}
                    </Typography>
                    {(feedback.email ||
                      (feedback.userId &&
                        feedback.userId.name !== "Anonymous")) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClickOpen(feedback)}
                        style={{ marginTop: "16px" }}
                      >
                        Reply
                      </Button>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        <Dialog open={open} onClose={this.handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Reply to Feedback</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              fullWidth
              multiline
              minRows={4}
              value={message}
              onChange={this.handleMessageChange}
            />
            {selectedFeedback && !selectedFeedback.email && (
              <FormControl component="fieldset" style={{ marginTop: "16px" }}>
                <FormLabel component="legend">Send As</FormLabel>
                <RadioGroup
                  aria-label="sendOption"
                  name="sendOption"
                  value={sendOption}
                  onChange={this.handleSendOptionChange}
                >
                  <FormControlLabel
                    value="notification"
                    control={<Radio />}
                    label="In-App Notification"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSend} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RatingFeedback.propTypes = {
  getAllRatingFeedbacks: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  getAllRatingFeedbacks,
  detail,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(RatingFeedback));
